import React, { Component } from 'react'
import '../styles/WhitePaper.css'
import map from "../images/worldmap.png"
import ItImage from '../images/IT@2x.png'
import SalesImage from '../images/Sales icon@2x.png'
import HrImage from '../images/HR@10x.png'
import MarketingImage from '../images/Marketing@2x.png'
import FinanceImage from '../images/Finance@10x.png'
import BusinessImage from '../images/buisiness@10x.png'
import CardSmall from '../component/CardSmall'
import Footer from '../component/Footer'
export default class WhitePaer1 extends Component {
    componentDidMount() {
        window.scrollTo(0, 0);
    }
    render() {

        return (
            <div>

                <div className="mainDivContainerImageHeader">
                    <div>
                        <h1 className="headerofContainerHeaderH1KV">White Paper:<br />
                Strategically Managing MQLs

         </h1>
                    </div>
                </div>


                <div className="gridCategoryheymaincontainerKV">
                    <div>
                        <div className="divofcontentwhitePaperKV">
                            The challenge most teams encounter in getting MQLs to sales development representatives
                            (SDRs) is having cycle through the sales funnel. Without the right strategy for handling MQLs,
                            firms find it difficult to get their return on investment (ROI) within the desired timeframe.
</div>
                        <div className="divofcontentwhitePaperKV">
                            Strategically managing MQLs is critical to being able to generate the right ROI.
                            What separates MQLs from other leads is behavior; MQLs often take action toward your organization and/or services.
</div>
                        <div className="divofcontentwhitePaperKV">
                            Enter intent data. Intent data is the lifeblood of sales and marketing because it offers insights into
                            online actions that indicate potential to purchase. Intent data removes the guesswork in lead analysis and helps
                            shorten the lead to sale conversion cycle. Intent data allows firms to focus more time, resources, and energy on active consumers.
</div>
                        <div className="divofcontentwhitePaperKV">
                            Check out this white paper to accelerate your MQL management!
    </div>
                    </div>

                    <div className="contactDownloadFormKV">
                        <form action="https://formsubmit.co/kislayvats22@gmail.com" method="POST">
                            <input type="hidden" name="_next" value="https://bili.co.in/" />
                            <div className="gridname">
                                <div className="but aas">

                                    <input required type="text" className="aaa" name="first_name" id="name" placeholder="First Name" />
                                </div>
                                <div className="but aas">

                                    <input required type="text" className="aaa" name="Last_name" id="name" placeholder=" Last Name" />
                                </div>
                            </div>
                            <div className="but">

                                <input required type="email" id="mail" className="aaa" name="email" placeholder="Email Address" />

                            </div>

                            <div className="but">

                                <input required type="text" className="aaa" name="company-name" id="msg" placeholder="Company Name" />
                            </div>
                            <div className="buted">
                                <input required className="aab" id="captcha" type="checkbox" name="_captcha" value="true" />
                                <label for="captcha" className="labelas" >I'm not a robot</label>
                                {/* <img src="https://upload.wikimedia.org/wikipedia/commons/thumb/a/ad/RecaptchaLogo.svg/1200px-RecaptchaLogo.svg.png"
                                    alt="alt" className="asdfgh" /> */}
                            </div>
                            <div className="butuon">

                                <button type="submit" className=" button btn">Download Now</button>
                                <input type="hidden" name="_captcha" value="false" />

                            </div>

                        </form>
                    </div>
                </div>


                <div className="h1textaligncenter1" style={{ textTransform: "uppercase" }}>THE VENALICIUMB2B GLOBAL AUDIENCE OF MORE THAN 125 MILLION TECHNOLOGY BUYERS</div>
                <div className="smallCrdInMarketKV">
                    <CardSmall
                        title="IT"
                        image={ItImage} />

                    <CardSmall
                        title="Sales"
                        image={SalesImage} />

                    <CardSmall
                        title="HR"
                        image={HrImage} />

                    <CardSmall
                        title="Business"
                        image={BusinessImage} />

                    <CardSmall
                        title="Finance"
                        image={FinanceImage} />

                    <CardSmall
                        title="Marketing"
                        image={MarketingImage} />
                </div>
                <div className="worldmap1">
                    <img src={map} alt="alt" className="worldmap" />
                </div>
                <Footer />

            </div>



        )
    }
}
