import React, { useEffect } from 'react'
import '../styles/InMarketOpp.css'
import Button from '../component/Button'
import CardSmall from '../component/CardSmall'
import map from "../images/worldmap.png"

import DataEnrichment from '../images/Data Enrichment@4x.png'
import PureConversion from '../images/Lead Follow Up@4x.png'
import FullFunnelImage from '../images/Full-Funnel B2B Demand Generation@4x.png'

import ItImage from '../images/IT@2x.png'
import SalesImage from '../images/Sales icon@2x.png'
import HrImage from '../images/HR@10x.png'
import MarketingImage from '../images/Marketing@2x.png'
import FinanceImage from '../images/Finance@10x.png'
import BusinessImage from '../images/buisiness@10x.png'
import Footer from '../component/Footer'
import Talk from '../component/Talk'
import Navbar from '../component/Navbar'

export default function InMarketOpp() {
    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])
    return (
        <div>
            <Navbar />

            <div className="wallpaper3as">
                <div className="headingwallpaper1">
                    <div className="headingshifting">

                        <h1 className="h2wallpaper1 animate__animated animate__backInLeft animate__delay-.5s animate__duration-2s">OPTIMIZED LEAD MANAGEMENT</h1>
                        <h1 className="h1wallpaper1 animate__animated animate__backInRight animate__delay-.5s animate__duration-2s">Lead Development Solutions</h1>
                    </div>
                </div>
            </div>



            <div className="Div1stInMarketKV">
                <div className="felxdj" >
                    <div data-aos="zoom-in">
                        <h1 className="h1Div3InMarketKV">V3Research: B2B Data Enrichment and Lead Discovery</h1>
                        <p className="paraDiv3InMarketKV">Optimization of your lead database to increase sales & marketing ROI.</p>
                        <div className="buttonDiv1InMarketKV" > <Button linka="/venalicium-b2b-blog-news" title="V3 Research" /></div>
                    </div>
                </div>
                <div className="felxdj">
                    <img src={DataEnrichment} alt="alt" className="ImageOfDataEnrichmentKV" data-aos="zoom-in" />
                </div>
            </div>
            {/* 1st DIV ENDS */}

            {/* 2nd DIV STARTS */}

            <div className="Div2ndInMarketKV">
                <div >
                    <div className="felxdj">
                        <img src={PureConversion} alt="alt" className="ImagePureConversionDiv2KV" data-aos="zoom-in" />
                    </div>
                </div>
                <div className="felxdj">
                    <div data-aos="zoom-in">
                        <h1 className="h1Div2InMarketPureConversionKV">V3Conversion: Optimized Lead Follow Up</h1>
                        <p className="paraDiv2InMarketKV">Go from prospects to scheduled meetings in 15-20 business days.</p>
                        <div className="ButtonPureConversionDiv2KV"><Button linka="/lead-total-development-solutions/venalicium-B2B-lead-conversion" title="V3 Conversion" /> </div>
                    </div>
                </div>
            </div>

            {/* 2nd DIV Ends */}

            {/* 3rd DIV STARTS */}
            <div className="Div3InMarketKV">
                <div className="felxdj">
                    <div data-aos="zoom-in">
                        <h1 className="h1Div3InMarketKV">V3Funnel: Full-Funnel Demand Generation
</h1>
                        <p className="paraDiv3InMarketKV">Combine lead generation with lead development to drive increased conversion rates.</p>
                        <div className="ButtonPureConversionDiv3KV"><Button linka="/lead-generation-services/b2b-lead-generation-funnel-bottom-of-funnel" title="V3Funnel" /> </div>
                    </div>
                </div>
                <div >
                    <div className="felxdj"><img src={FullFunnelImage} alt="alt" className="ImageDiv3InMarketKV" data-aos="zoom-in" /> </div>
                </div>
            </div>
            {/* 3rd DIV ENDS */}

            {/* 4th DIV STARTS */}
            <div className="talkdiv">
                <Talk heading1="Take Your B2B Content " heading2="Syndication to the Next Level." />
            </div>

            {/* 4th DIV STARTS*/}
            <div className="headingDiv4InMarket" data-aos="zoom-in" >THE V3B2B GLOBAL AUDIENCE OF MORE THAN 125 MILLION TECHNOLOGY BUYERS</div>
            <div className="smallCrdInMarketKV">
                <CardSmall
                    linka="/technology-buyers/it-audience"
                    title="IT"
                    image={ItImage} />

                <CardSmall
                    linka="/technology-buyers/sales-audience"
                    title="Sales"
                    image={SalesImage} />

                <CardSmall
                    linka="/technology-buyers/hr-audience"
                    title="HR"
                    image={HrImage} />

                <CardSmall
                    linka="/technology-buyers/business-audience"
                    title="Business"
                    image={BusinessImage} />

                <CardSmall
                    linka="/technology-buyers/finance-audience"
                    title="Finance"
                    image={FinanceImage} />

                <CardSmall
                    linka="/technology-buyers/marketing-audience"
                    title="Marketing"
                    image={MarketingImage} />
            </div>
            <div className="worldmap1" >
                <img src={map} alt="alt" className="worldmap" data-aos="zoom-in" />
            </div>

            <Footer />

        </div>
    )
}
