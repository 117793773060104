import React, { useEffect } from 'react'
import '../styles/ContentSyndication.css'
import map from "../images/worldmap.png"
import books from '../images/traget.png'
// import inter from '../images/contentbg_contentsyndication.png'
import account from '../images/contentaccount.png'
// import Button from '../component/Button'
// import Card from '../component/Card'
import data from '../images/data.png'
import data1 from '../images/marketing.png'
import data2 from '../images/tactical.png'
import Talk from '../component/Talk'
import Footer from '../component/Footer'
import Button from '../component/Button'
import Card from '../component/Card'
import Navbar from '../component/Navbar'
// import Button from '../component/Button'

export default function Contentsyndication() {
    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])
    return (
        <div>
            <Navbar />
            <div className="wallpaper2as">
                <div className="headingwallpaper">
                    <h1 className="h2wallpaper" data-aos="fade-up">VENALICIUM SYNDICATION:</h1>
                    <h1 className="h1wallpaper" data-aos="fade-up">Powerful B2B<br /> Content Syndication</h1>
                </div>
            </div>
            <div className="leftgrid" >
                <div className="leftside" data-aos="fade-up">
                    <h2 className="lefthead">Target and Engage the Right B2B Buyers through Content Syndication</h2>
                    <p className="leftpara">
                        The marketers of the present times face a common challenge that is maintaining consistency in the
                        flow of quality leads when they have to cast a wide network with their content marketing. It is
                        becoming difficult for them to reach the right buyers at the right time even if there is an
                        abundance of intent-data. Get on with us and draw yourself apart from the crowd to gain insights
                        in action into your in-market buyer’s intent activity!
                       </p>
                </div>

                <div className="imgrightas">
                    <img src={books} alt="alt" className="imgrightdj" data-aos="fade-up" />
                </div>
            </div>
            <div className="intermediate" data-aos="fade-up">
                <div className="felxdj">
                    <div>

                        <div className="lefthead1">Content Syndication with Actionable Insights</div>
                        <div className="leftpara1">Powered by the largest intent ecosystem in the space, V3Syndication pushes your content into the hands of your target buyers using multi-channel outreach efforts. V3Syndication leads are supplemented with robust intent data layering, providing marketing and sales teams with accurate intent data to accelerate the buying process. V3Syndication with ABM targeting reaches and engages with the right decision makers within your target ABM list. Identify and engage with buyers across multiple departments and business units in one place.
                    <br />
                    Speak with a team member to discuss your content syndication goals.
                        <div className="marginforbutton">
                                <Button title="Contact Us Today" />
                            </div>

                        </div>
                    </div>
                </div>
                <div className="felxdj" >
                    <img src={account} alt="alt" className="accountright" />
                </div>
            </div>
            <h1 className="h1textaligncenter" >Powerful B2B Content Syndication That Works</h1>
            <div className="cardsgrid">
                <div className="cardsingle"><Card image={data} title="INTENT DATA" subtitle="By aggregating both online and phone-verified intent, Venalicium B2B identifies companies that are in-market and integrates your chosen keywords to build a targeted account list. Leads that engage go through an extensive QA process." /></div>
                <div className="cardsingle"><Card image={data1} title="ABM" subtitle="Build a custom ABM list or strengthen your own from our global audience of more than 125 million technology buyers’ behavior intent data." /></div>
                <div className="cardsingle"><Card image={data2} title="CASE STUDIES " subtitle="Clients use V3Syndication to successfully scale and increase sales conversions via multi-channel outreach campaigns with guaranteed lead volumes." /></div>
            </div >
            <div className="talkdiv">
                <Talk heading1="Take Your B2B Content " heading2="Syndication to the Next Level." />
            </div>
            <div className="worldmap1 mar" data-aos="zoom-in" >
                <img src={map} alt="alt" className="worldmap" />
            </div>
            <Footer />
        </div >
    )
}
