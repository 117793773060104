import React, { useEffect } from 'react';
import './App.css';
import { Route, Switch } from 'react-router-dom';
import Landing from './pages/Landing';
import Leadgeneration from './pages/Leadgeneration';
import Contentsyndication from './pages/Contentsyndication';
import InMarketOpp from './pages/InMarketOpp';
import AccountBasedMarketing from './pages/AccountBasedMarketing';
import InMeeting from './pages/InMeeting';
import InMarketLeads from './pages/InMarketLeads';
import BottomOfFunnel from './pages/BottomOfFunnel';
// import Navbar from './component/Navbar';
import Information from './pages/Information';
import StrategicContent from './pages/StrategicContent';
import Fullservice from './pages/Fullservice';
import Development from './pages/Development';
import WhitePaper1 from './pages/WhitePaper1';
import WhitePaper2 from './pages/WhitePaper2';
import WhitePaper3 from './pages/WhitePaper3';
import WhitePaper4 from './pages/WhitePaper4';
import WhitePaper5 from './pages/WhitePaper5';
import WhitePaper6 from './pages/WhitePaper6';
import WhitePaper7 from './pages/WhitePaper7';
import WhitePaper8 from './pages/WhitePaper8';
import WhitePaper9 from './pages/WhitePaper9';
import WhitePaper10 from './pages/WhitePaper10';
import WhitePaper11 from './pages/WhitePaper11';
import WhitePaper12 from './pages/WhitePaper12';
import WhitePaper13 from './pages/WhitePaper13';
import WhitePaper14 from './pages/WhitePaper13';
import Resources from './pages/Resources';
import Audience from './pages/Audience';
import Its from './pages/Its';
import Sales from './pages/Sales';
import Marketing from './pages/Marketing';
import Finance from './pages/Finance';
import Bussines from './pages/Bussines';
import Hr from './pages/Hr';
import Aos from 'aos';
import 'aos/dist/aos.css';
import About from './pages/About';
import Contactus from './pages/Contactus';
import V3b2b from './pages/V3b2b'

function App() {
  useEffect(() => {
    Aos.init({ duration: 2000 });
  }, [])

  return (
    <div >

      <Switch>
        <Route exact path="/" component={Landing} />
        <Route exact path="/lead-generation-services" component={Leadgeneration} />
        <Route exact path="/lead-generation-services/content-syndication-lead-generation" component={Contentsyndication} />
        <Route exact path="/lead-total-development-solutions" component={InMarketOpp} />
        <Route exact path="/lead-generation-services/abm-lead-generation" component={AccountBasedMarketing} />
        <Route exact path="/lead-generation-services/venalicium-b2b-lead-generation-funnel" component={InMeeting} />
        <Route exact path="/lead-generation-services/b2b-lead-generation-trends-inmarket-leads" component={InMarketLeads} />
        <Route exact path="/lead-generation-services/b2b-lead-generation-funnel-bottom-of-funnel" component={BottomOfFunnel} />
        <Route exact path="/content-creation-solutions/content-survey" component={Information} />
        <Route exact path="/content-creation-solutions" component={StrategicContent} />
        <Route exact path="/content-creation-solutions/content-creations-and-marketing" component={Fullservice} />
        <Route exact path="/lead-total-development-solutions/venalicium-B2B-lead-conversion" component={Development} />
        <Route exact path="/whitepaper1" component={WhitePaper1} />
        <Route exact path="/whitepaper2" component={WhitePaper2} />
        <Route exact path="/whitepaper3" component={WhitePaper3} />
        <Route exact path="/whitepaper4" component={WhitePaper4} />
        <Route exact path="/whitepaper5" component={WhitePaper5} />
        <Route exact path="/whitepaper6" component={WhitePaper6} />
        <Route exact path="/whitepaper7" component={WhitePaper7} />
        <Route exact path="/whitepaper8" component={WhitePaper8} />
        <Route exact path="/whitepaper9" component={WhitePaper9} />
        <Route exact path="/whitepaper10" component={WhitePaper10} />
        <Route exact path="/whitepaper11" component={WhitePaper11} />
        <Route exact path="/whitepaper12" component={WhitePaper12} />
        <Route exact path="/whitepaper13" component={WhitePaper13} />
        <Route exact path="/whitepaper14" component={WhitePaper14} />

        <Route exact path="/lead-generation-resources" component={Resources} />
        <Route exact path="/technology-buyers" component={Audience} />
        <Route exact path="/technology-buyers/it-audience" component={Its} />
        <Route exact path="/technology-buyers/sales-audience" component={Sales} />
        <Route exact path="/technology-buyers/marketing-audience" component={Marketing} />
        <Route exact path="/technology-buyers/finance-audience" component={Finance} />
        <Route exact path="/technology-buyers/business-audience" component={Bussines} />
        <Route exact path="/technology-buyers/hr-audience" component={Hr} />
        <Route exact path="/aboutus-lead-generation-services" component={About} />
        <Route exact path="/contact-venalicium-b2b" component={Contactus} />
        <Route exact path="/venalicium-b2b-blog-news" component={V3b2b} />
      </Switch>
    </div>
  );
}

export default App;
