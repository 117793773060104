import React, { useEffect } from 'react'
import '../styles/Development.css'
import back from '../images/Leaddev.png'
import content from "../images/setting.png"
import Button from '../component/Button'
import map from "../images/worldmap.png"
import ItImage from '../images/IT@2x.png'
import SalesImage from '../images/Sales icon@2x.png'
import HrImage from '../images/HR@10x.png'
import MarketingImage from '../images/Marketing@2x.png'
import FinanceImage from '../images/Finance@10x.png'
import BusinessImage from '../images/buisiness@10x.png'
import CardSmall from '../component/CardSmall'
import tick from '../images/tick@4x.png'
import Footer from '../component/Footer'
import Redcard from '../component/Redcard'

import data1 from '../images/sun.png'
import data2 from '../images/calculator.png'
import data3 from '../images/calender.png'
import data4 from '../images/eye.png'
import data5 from '../images/qualified.png'
import Navbar from '../component/Navbar'

export default function Development() {
    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])
    return (
        <div>
            <Navbar />
            <div className="wallpaper7as">
                <img src={back} alt="alt" className="wallpaper7" />

            </div>
            <div className="margintopddaa">
                <div className="gridleftimage  ">
                    <div className="imagecontainer">
                        <img className="imageingrid" src={content} alt="alt" data-aos="zoom-in-right" />
                    </div>
                    <div className="paragraphdiv " data-aos="zoom-in-left">
                        <div>
                            <h1 className="h1part1as">Move Leads Through Your Sales & Marketing Funnel</h1>
                            <p className="paradetails1">Even the most ideal leads can fail to convert for a variety of factors related to timing, brand awareness, budget, or intent. Most sales and marketing organizations’ biggest gap isn’t in generating leads, but in effectively moving them through the buying journey.</p>
                            <p className="paradetails1">Venalicium B2B’s Conversion solution provides a cost-effective means of guiding more leads through your marketing and sales funnel and takes back control of your pipeline. This lead development and pipeline accelerant utilizes an industry proven multi-channel approach of customized email and phone outreach conducted by experienced, U.S.-based agents, all with turn-key execution.</p>
                            <div className="marginforbutton">
                                <Button title="Contact Us " style={{ width: "30%" }} />
                            </div>
                        </div>
                    </div>

                </div>
            </div>

            <div className="optimizeasdj">
                <div className="gridfortick">
                    <div data-aos="fade-up-right">
                        <div className="headingoptimize">Lead Development<br /> Challenges</div>
                        <div className="parafortick">No matter where you’re having a problem in your conversion pipeline, Conversion fixes bottlenecks in your pipeline and helps solve a variety of challenges such as:</div>
                        <div className="ticksub"><img src={tick} alt="alt" className="tick" />
                            <div className="tick1">Lack internal infrastructure to successfully move leads through the sales and marketing pipeline</div>
                        </div>
                        <div className="ticksub"><img src={tick} alt="alt" className="tick" />
                            <div className="tick1">Your LDR team currently has bandwidth challenges in covering your lead flow adequately</div>
                        </div>
                        <div className="ticksub"><img src={tick} alt="alt" className="tick" />
                            <div className="tick1">Adding SDR headcount in marketing is both expensive and time consuming</div>
                        </div>
                        <div className="ticksub"><img src={tick} alt="alt" className="tick" />
                            <div className="tick1">Too many MQLs for SDRs, BDRs, for sales to handle</div>
                        </div>
                    </div>
                    <div></div>
                </div>
                <div className="bottom-div" data-aos="fade-up">Unlike most lead development solutions in the space, Conversion works on a per lead pricing model, helping to better manage the ebbs and flows of leads more cost effectively. Building out internal resources is expensive and time consuming; Conversion cuts your lead development and qualification costs by 25-30% on average versus in house.</div>
            </div>
            <div className="h1textaligncenter">How Conversion Works</div>
            <div className="forgridcardpink">

                <Redcard title="Step one" subtitle="Client provides leads at any stage of their sales and marketing funnel for our U.S.-based agents to target." />
                <Redcard title="Step two" subtitle="Venalicium B2B takes an integrated approach to lead development across two main channels: custom email and phone outreach." />
                <Redcard title="Step three" subtitle="Conversion will free blockages and move leads through your sales/marketing funnel." />
            </div>
            <div className="h1textaligncenter">Conversion Use Cases</div>
            <div className="maincarddj">
                <img src={data1} className="imgcardfor6" alt="alt" data-aos="flip-right" />
                <img src={data2} className="imgcardfor6" alt="alt" data-aos="zoom-in" />
                <img src={data3} className="imgcardfor6" alt="alt" data-aos="flip-left" />

            </div>
            <div className="maincaard2">
                <img src={data4} className="imgcardfor6" alt="alt" data-aos="flip-left" />
                <img src={data5} className="imgcardfor6" alt="alt" data-aos="flip-right" />
            </div>
            <div className="h1textaligncenteras" data-aos="fade-up">Venalicium B2B leverages our technology to identify and connect your programs with high quality U.S.-based phone talent. We select the top 3% of callers out of thousands of applicants each month with 5+ years’ experience. All calls are QA’d either by machine learning or manual QA with more than 20 call criteria.</div>

            <div className="h1textaligncenter1" data-aos="fade-up" style={{ textTransform: "uppercase" }}>THE VENALICIUM B2B GLOBAL AUDIENCE OF MORE THAN 125 MILLION TECHNOLOGY BUYERS</div>
            <div className="smallCrdInMarketKV">
                <CardSmall
                    linka="/technology-buyers/it-audience"
                    title="IT"
                    image={ItImage} />

                <CardSmall
                    linka="/technology-buyers/sales-audience"
                    title="Sales"
                    image={SalesImage} />

                <CardSmall
                    linka="/technology-buyers/hr-audience"
                    title="HR"
                    image={HrImage} />

                <CardSmall
                    linka="/technology-buyers/business-audience"
                    title="Business"
                    image={BusinessImage} />

                <CardSmall
                    linka="/technology-buyers/finance-audience"
                    title="Finance"
                    image={FinanceImage} />

                <CardSmall
                    linka="/technology-buyers/marketing-audience"
                    title="Marketing"
                    image={MarketingImage} />
            </div>
            <div className="worldmap1" data-aos="zoom-in">
                <img src={map} alt="alt" className="worldmap" />
            </div>
            <Footer />
        </div>

    )
}
