import React, { useEffect } from 'react'
import '../styles/Audience.css'
import map from "../images/worldmap.png"
import ItImage from '../images/IT@2x.png'
import SalesImage from '../images/Sales icon@2x.png'
import HrImage from '../images/HR@10x.png'
import MarketingImage from '../images/Marketing@2x.png'
import FinanceImage from '../images/Finance@10x.png'
import BusinessImage from '../images/buisiness@10x.png'
import CardSmall from '../component/CardSmall'
import logo from '../images/log.PNG'
import Button from '../component/Button'
import Button1 from '../component/Button1'
import Button2 from '../component/Button2'
import Talk from '../component/Talk'
import Footer from '../component/Footer'
import Navbar from '../component/Navbar'

export default function Audience() {
    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])
    return (
        <div>

            <Navbar />
            <div className="wallpaper10as">
                <div className="headingwallpaper1">

                    <h1 className="h1wallpaper1 aosbs" data-aos="zoom-in">Global B2B Technology Buyer<br />Audience</h1>
                </div>
            </div>
            <div className="h1textaligncenter1" style={{ textTransform: "uppercase" }}>THE VENALICIUM B2B GLOBAL AUDIENCE OF MORE THAN 125 MILLION TECHNOLOGY BUYERS</div>
            <div className="smallCrdInMarketKV">
                <CardSmall
                    linka="/technology-buyers/it-audience"
                    title="IT"
                    image={ItImage} />

                <CardSmall
                    linka="/technology-buyers/sales-audience"
                    title="Sales"
                    image={SalesImage} />

                <CardSmall
                    linka="/technology-buyers/hr-audience"
                    title="HR"
                    image={HrImage} />

                <CardSmall
                    linka="/technology-buyers/business-audience"
                    title="Business"
                    image={BusinessImage} />

                <CardSmall
                    linka="/technology-buyers/finance-audience"
                    title="Finance"
                    image={FinanceImage} />

                <CardSmall
                    linka="/technology-buyers/marketing-audience"
                    title="Marketing"
                    image={MarketingImage} />
            </div>
            <div className="worldmap1">
                <img src={map} alt="alt" className="worldmap" data-aos="zoom-in" />
            </div>
            <div className="h1textaligncenter">Venalicium B2B Demand Generation Solutions</div>
            <div className="h1textaligncenteras">
                Unique and innovative tactics designed as a result of years of testing,
                research and experiences. We provide demand generation solution at every
                step of marketing and sales with our vast network of over 100 million in-market
                technology buyers.
            </div>
            <div className="logogroup ">
                <div className="logogrid"><img className="logonavbarasdj" src={logo} alt="alt" />
                    <h4 className="h3logofirstas">Lead Generation</h4>
                    <Button linka="/lead-generation-services" title="Learn More!" />
                </div>
                <div className="logogrid"><img className="logonavbarasdj" src={logo} alt="alt" />
                    <h4 className="h3logomiddleas">Content Creation</h4>
                    <Button1 linka="/content-creation-solutions" title="Learn More!" />
                </div>
                <div className="logogrid"><img className="logonavbarasdj" src={logo} alt="alt" />
                    <div className="kislayisbeep">

                        <h4 className="h3logolastas">Lead Development</h4>
                    </div>
                    <Button2 linka="/lead-total-development-solutions" title="Learn More!" />
                </div>
            </div>
            <div className="talkdiv">
                <Talk heading1="Talk with one of our team members " heading2="today about your target audience." />
            </div>
            <div className="finalgrid">
                <div className="formargindiv">
                    <div className="paragraphdiv">
                        <div>
                            <h1 className="h1part1as">Take your Sales and Marketing Initiatives to the Global Level</h1>
                            <p className="paradetails">
                                We focus on being reliable, reliable for accelerating
                                the bottom-line initiatives of our clients to a global level.
                                Why struggle through a rough road to your zenith when you can
                                take up a smarter way?

                 </p>
                            {/* <div className="marginforbutton">
                                <Button title="How SideCar Found Success" style={{ width: "70%" }} />
                            </div> */}
                        </div>
                    </div>
                </div>

                <div className="backgrid"></div>
            </div>
            <Footer />
        </div>
    )
}
