import React from 'react'
import '../styles/Funnel.css'
export default function Funnel() {

    return (
        <div className="funnelbody">
            <svg width="574" height="607" viewBox="0 0 274 307" fill="none" xmlns="http://www.w3.org/2000/svg">
                <g id="ssss 1">
                    <g id="Layer 2">
                        <g id="Layer 1">
                            <g id="sixthred">
                                <path id="Vector"
                                    d="M176.914 232.207L173.024 255.499C173.024 255.499 169.459 268.736 136.404 268.736C103.349 268.736 99.9197 256.034 99.9197 256.034L96.0149 232.395L96.0978 232.606C97.3567 235.674 100.854 238.448 105.897 240.672H105.965C109.862 242.466 115.214 244.102 122.549 245.036C131.651 246.276 140.878 246.276 149.98 245.036L150.734 244.931H150.794C155.403 244.357 159.931 243.255 164.287 241.644L164.679 241.494C170.898 239.164 175.233 236.059 176.672 232.576V232.531C176.808 232.365 176.868 232.207 176.914 232.207Z"
                                    fill="#D34242" />
                                <path id="Vector_2"
                                    d="M177.208 230.315C177.175 231.092 177.004 231.858 176.703 232.576V232.621C175.263 236.104 170.928 239.209 164.71 241.539L164.318 241.689C159.941 243.234 155.421 244.336 150.824 244.976H150.764L150.01 245.082C140.909 246.321 131.681 246.321 122.579 245.082C115.275 244.147 109.923 242.511 105.995 240.717H105.928C100.854 238.493 97.3567 235.719 96.128 232.651C96.1247 232.634 96.1247 232.616 96.128 232.599C96.1293 232.573 96.1293 232.548 96.128 232.523V232.47C95.8035 231.641 95.6572 230.752 95.6983 229.862C96.0903 221.05 114.687 214.032 137.233 214.191C159.78 214.349 177.6 221.502 177.208 230.315Z"
                                    fill="#E84949" />
                            </g>
                            <g id="fifthyellow">
                                <path id="Vector_3"
                                    d="M88.5974 203.607C88.5974 203.607 95.0576 219.12 135.537 219.12C176.017 219.12 184.196 203.562 184.196 203.562L178.421 226.719C178.421 226.719 172.896 241.396 135.635 241.396C98.3744 241.396 94.4998 227.269 94.4998 227.269L88.5974 203.607Z"
                                    fill="#F9932E" />
                                <path id="Vector_4"
                                    d="M184.979 200.735C184.939 201.805 184.682 202.857 184.226 203.826C180.042 212.819 159.704 219.558 135.567 219.392C112.056 219.234 92.7359 212.608 88.6276 203.878C88.052 202.708 87.7749 201.413 87.821 200.109C88.2884 189.624 110.435 181.264 137.263 181.453C164.091 181.641 185.447 190.25 184.979 200.735Z"
                                    fill="#FFB770" />
                            </g>
                            <g id="fourthgreen">
                                <path id="Vector_5"
                                    d="M78.1647 174.269C78.1647 174.269 87.8813 193.009 135.349 193.009C182.816 193.009 194.673 174.163 194.673 174.163L186.547 197.803C186.547 197.803 180.886 214.65 135.175 214.65C89.4643 214.65 86.2154 197.803 86.2154 197.803L78.1647 174.269Z"
                                    fill="#4FB972" />
                                <path id="Vector_6"
                                    d="M136.381 193.242C169.158 193.242 195.729 182.861 195.729 170.055C195.729 157.249 169.158 146.868 136.381 146.868C103.605 146.868 77.034 157.249 77.034 170.055C77.034 182.861 103.605 193.242 136.381 193.242Z"
                                    fill="#4CC775" />
                            </g>
                            <g id="thirdblue">
                                <path id="Vector_7"
                                    d="M65.4254 146.928C65.4254 146.928 79.2427 167.922 134.813 167.922C190.384 167.922 206.81 146.86 206.81 146.86L197.055 168.721C197.055 168.721 188.688 188.267 135.009 188.267C81.3307 188.267 75.059 168.608 75.059 168.608L65.4254 146.928Z"
                                    fill="#00D1D1" />
                                <path id="Vector_8"
                                    d="M209.395 140.031C208.694 155.861 175.301 168.449 134.813 168.178C94.3264 167.907 62.0785 154.851 62.787 139.036C63.4956 123.221 96.8818 110.61 137.369 110.889C177.856 111.167 210.081 124.216 209.395 140.031ZM167.762 139.744C168.064 132.915 154.134 127.276 136.645 127.156C119.157 127.035 104.729 132.485 104.42 139.315C104.111 146.144 118.049 151.79 135.537 151.903C153.026 152.016 167.453 146.581 167.762 139.744Z"
                                    fill="#01D8D8" />
                            </g>
                            <g id="secondblue">
                                <path id="Vector_9"
                                    d="M184.12 107.866C183.924 112.238 179.884 116.226 173.288 119.361C170.129 118.027 156.87 113.105 136.517 113.105C115.81 113.105 102.483 118.246 99.5805 119.467C92.5776 116.12 88.4392 111.846 88.6502 107.225C89.11 96.9129 110.857 88.7115 137.218 88.8924C163.579 89.0733 184.58 97.5687 184.12 107.866Z"
                                    fill="#2C6A91" />
                                <path id="Vector_10"
                                    d="M227.366 108.145C226.492 127.781 185.04 143.423 134.791 143.076C84.542 142.729 44.5222 126.538 45.3967 106.893C46.2711 87.2491 87.723 71.6452 137.972 71.9769C188.221 72.3086 228.241 88.5155 227.366 108.145ZM184.113 107.828C184.58 97.5311 163.579 89.0356 137.218 88.8547C110.857 88.6738 89.11 96.8978 88.6502 107.202C88.1904 117.507 109.184 125.995 135.552 126.176C161.92 126.357 183.653 118.155 184.113 107.828Z"
                                    fill="#468DC2" />
                                <path id="Vector_11"
                                    d="M49.2562 117.824C49.2562 117.824 69.0663 141.832 134.791 141.832C200.515 141.832 222.233 120.869 223.507 117.824L211.046 140.136C211.046 140.136 199.091 162.969 135.04 162.969C70.9885 162.969 60.6613 138.094 60.6613 138.094L49.2562 117.824Z"
                                    fill="#3979A9" />
                            </g>
                            <g id="firstpurple">
                                <path id="Vector_12"
                                    d="M203.704 74.3288C203.704 78.6331 200.968 82.6885 196.166 86.2616C184.859 77.9245 162.079 72.2181 135.801 72.2181C109.523 72.2181 86.6979 77.9245 75.3907 86.2616C70.5513 82.6885 67.8526 78.6331 67.8526 74.3288C67.8526 60.0064 98.2764 48.3525 135.801 48.3525C173.326 48.3525 203.704 59.9838 203.704 74.3288Z"
                                    fill="url(#paint0_linear)" />
                                <path id="Vector_13"
                                    d="M35.5368 93.0233C35.5368 93.0233 56.6812 129.689 136.841 129.689C217.001 129.689 236.344 95.4807 236.344 95.4807L227.004 110.459C227.004 110.459 207.405 136.842 136.849 136.842C66.2923 136.842 46.6706 112.004 46.6706 112.004L35.5368 93.0233Z"
                                    fill="#483879" />
                                <path id="Vector_14"
                                    d="M203.704 48.8124C185.696 41.8924 162.35 37.7238 136.841 37.7238C110.292 37.7238 86.0948 42.2467 67.8149 49.6642C46.859 58.1747 33.69 70.4995 33.69 84.2112C33.69 97.923 46.859 110.248 67.8149 118.758C86.0948 126.183 110.292 130.699 136.841 130.699C162.35 130.699 185.696 126.53 203.704 119.61C225.911 111.085 240 98.3904 240 84.1811C240 69.9718 225.911 57.3229 203.704 48.8124ZM135.763 100.29C98.2387 100.29 67.8149 88.6813 67.8149 74.3288C67.8149 59.9763 98.2387 48.3525 135.763 48.3525C173.288 48.3525 203.704 59.9838 203.704 74.3288C203.704 88.6738 173.288 100.29 135.763 100.29Z"
                                    fill="url(#paint1_linear)" />
                            </g>
                        </g>
                    </g>
                </g>
                <defs>
                    <linearGradient id="paint0_linear" x1="67.8149" y1="67.3108" x2="203.704" y2="67.3108"
                        gradientUnits="userSpaceOnUse">
                        <stop stop-color="#745BC4" />
                        <stop offset="0.51" stop-color="#5F49AF" />
                        <stop offset="1" stop-color="#493699" />
                    </linearGradient>
                    <linearGradient id="paint1_linear" x1="33.69" y1="5771.48" x2="56498.7" y2="5771.48"
                        gradientUnits="userSpaceOnUse">
                        <stop stop-color="#745BC4" />
                        <stop offset="0.51" stop-color="#5F49AF" />
                        <stop offset="1" stop-color="#493699" />
                    </linearGradient>
                </defs>
            </svg>

        </div>
    )
}
