
import map from "../images/worldmap.png"
import ItImage from '../images/IT@2x.png'
import SalesImage from '../images/Sales icon@2x.png'
import HrImage from '../images/HR@10x.png'
import MarketingImage from '../images/Marketing@2x.png'
import FinanceImage from '../images/Finance@10x.png'
import BusinessImage from '../images/buisiness@10x.png'
import CardSmall from '../component/CardSmall'
import Footer from '../component/Footer'
import '../styles/WhitePaper.css'
import React, { useEffect } from 'react'

export default function WhitePaper1() {



    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])
    return (
        <div>

            <div className="mainDivContainerImageHeader">
                <div >
                    <h1 className="headerofContainerHeaderH1KV">White Paper:<br /> Discover the positive impact intent data can
         have on your B2B lead generation marketing strategies.</h1>
                </div>
            </div>


            <div className="gridCategoryheymaincontainerKV">
                <div>
                    <div className="divofcontentwhitePaperKV">

                        To effectively generate leads, marketers turn to various marketing channels, including email, digital advertising and retargeting, blogging, social media, and beyond.
</div>
                    <div className="divofcontentwhitePaperKV">The challenge is accurately tracking target buyers through this marketing mix. Logging engagements and downloads and implementing lead scoring is an imperfect art.
</div>
                    <div className="divofcontentwhitePaperKV">What if you could track buyer user behavior through your funnel and across the wider web? Enter intent data.
</div>
                    <div className="divofcontentwhitePaperKV">Learn these best practices to get started using intent data to increase your marketing ROI.</div>
                </div>

                <div className="contactDownloadFormKV">
                    <form action="https://formsubmit.co/kislayvats22@gmail.com" method="POST">
                        <input type="hidden" name="_next" value="https://bili.co.in/" />
                        <div className="gridname">
                            <div className="but aas">

                                <input required type="text" className="aaa" name="first_name" id="name" placeholder="First Name" />
                            </div>
                            <div className="but aas">

                                <input required type="text" className="aaa" name="Last_name" id="name" placeholder=" Last Name" />
                            </div>
                        </div>
                        <div className="but">

                            <input required type="email" id="mail" className="aaa" name="email" placeholder="Email Address" />

                        </div>

                        <div className="but">

                            <input required type="text" className="aaa" name="company-name" id="msg" placeholder="Company Name" />
                        </div>

                        <div className="buted">
                            <input required className="aab" id="captcha" type="checkbox" name="_captcha" value="true" />
                            <label for="captcha" className="labelas" >I'm not a robot</label>
                            {/* <img src="https://upload.wikimedia.org/wikipedia/commons/thumb/a/ad/RecaptchaLogo.svg/1200px-RecaptchaLogo.svg.png"
                                    alt="alt" className="asdfgh" /> */}
                        </div>
                        <div className="butuon">

                            <button type="submit" className=" button btn">Download Now</button>
                            <input type="hidden" name="_captcha" value="false" />

                        </div>

                    </form>
                </div>

            </div>

            <div className="h1textaligncenter1" style={{ textTransform: "uppercase" }}>THE VENALICIUM B2B GLOBAL AUDIENCE OF MORE THAN 125 MILLION TECHNOLOGY BUYERS</div>
            <div className="smallCrdInMarketKV">
                <CardSmall
                    title="IT"
                    image={ItImage} />

                <CardSmall
                    title="Sales"
                    image={SalesImage} />

                <CardSmall
                    title="HR"
                    image={HrImage} />

                <CardSmall
                    title="Business"
                    image={BusinessImage} />

                <CardSmall
                    title="Finance"
                    image={FinanceImage} />

                <CardSmall
                    title="Marketing"
                    image={MarketingImage} />
            </div>
            <div className="worldmap1">
                <img src={map} alt="alt" className="worldmap" />
            </div>
            <Footer />

        </div>



    )
}

