import React, { Component } from 'react'

import '../styles/Resources.css';
import ResourceCard from '../component/ResourceCard';


import Button1 from '../component/Button1'

import Res1 from '../images/res1.PNG'
import Res2 from '../images/res2.PNG'
import Res3 from '../images/res3.PNG'
import Res4 from '../images/res4.PNG'
import Res5 from '../images/res5.PNG'
import Res6 from '../images/res6.PNG'
import Res7 from '../images/res7.PNG'
import Res8 from '../images/res8.PNG'
import Res9 from '../images/res9.PNG'
import Res10 from '../images/res10.PNG'
import Res11 from '../images/res11.PNG'
import Res12 from '../images/res12.PNG'
import Res13 from '../images/res13.PNG'
import Res14 from '../images/res14.PNG'
import Footer from '../component/Footer';
import Navbar from '../component/Navbar';

export default class Resources extends Component {

    componentDidMount() {
        window.scrollTo(0, 0);
    }
    render() {

        return (
            <div>
                <Navbar />
                <div className="resourcesheaderimage">
                    <div className="headingOfResourcesHeaderImageKV">
                        <h1 className="h1HeadingOfHeaderImageKV">
                            B2B Demand<br /> Generation Resources
    </h1>
                    </div>
                    <div className="WhitePaperhaeding">White Paper</div>
                </div>

                <div className="gridTemplateresourcesKV">
                    <div className="resourceCardfORdIFFERENTcARDkv" >
                        <ResourceCard image={Res1}
                            title="Discover the positive impact intent data can
         have on your B2B lead generation marketing strategies."
                        />

                        <Button1 linka="/whitepaper1"
                            title="Read More"
                            style={{ marginLeft: "40%" }}
                        />

                    </div>



                    <div className="resourceCardfORdIFFERENTcARDkv" >
                        <ResourceCard image={Res2}
                            title="Check out these best practices to kick-start your MQL strategy!"

                        />

                        <Button1 linka="/whitepaper2" title="Learn More !" style={{ marginLeft: "40%" }} />

                    </div>
                </div>

                <div className="gridTemplateresourcesKV">
                    <div className="resourceCardfORdIFFERENTcARDkv" >
                        <ResourceCard image={Res3}
                            title="Pipeline marketing is the next evolutionary step for marketers need to take to gain ownership of the pipeline and fuel revenue."
                        />

                        <Button1 linka="/whitepaper3" title="Learn More" style={{ marginLeft: "40%" }} />

                    </div>
                    <div className="resourceCardfORdIFFERENTcARDkv" >
                        <ResourceCard image={Res4}
                            title="Spring 2020 Digital Content Marketing Survey"

                        />

                        <Button1 linka="/whitepaper4" title="Read More" style={{ marginLeft: "40%" }} />

                    </div>
                </div>



                <div className="gridTemplateresourcesKV">
                    <div className="resourceCardfORdIFFERENTcARDkv" >
                        <ResourceCard image={Res5}
                            title="Learn what you need to make your ABM campaigns a success."

                        />

                        <Button1 linka="/whitepaper5" title="Read More" style={{ marginLeft: "40%" }} />

                    </div>
                    <div className="resourceCardfORdIFFERENTcARDkv" >
                        <ResourceCard image={Res6}
                            title="Anyone can be a publisher, but producing high quality, engaging content is far more involved than churning out a few blog posts."

                        />

                        <Button1 linka="/whitepaper6" title="Read More" style={{ marginLeft: "40%" }} />

                    </div>
                </div>


                <div className="gridTemplateresourcesKV">
                    <div className="resourceCardfORdIFFERENTcARDkv" >
                        <ResourceCard image={Res7}
                            title="Everything you need to devise and implement a successful content
         syndication strategy for your business is right here in this resource.
        "

                        />

                        <Button1 linka="/whitepaper7" title="Read More" style={{ marginLeft: "40%" }} />

                    </div>
                    <div className="resourceCardfORdIFFERENTcARDkv" >
                        <ResourceCard image={Res8}
                            title="Data-driven marketing is about responding to customer needs 
        promptly based on buying behavior and data collected on and offline."

                        />

                        <Button1 linka="/whitepaper8" title="Read More" style={{ marginLeft: "40%" }} />

                    </div>
                </div>



                <div className="gridTemplateresourcesKV">
                    <div className="resourceCardfORdIFFERENTcARDkv" >
                        <ResourceCard image={Res9}
                            title="Discover the best-practices you need to establish to strengthen your
         lead generation strategies and hit your increasing revenue goals.
        "
                        />

                        <Button1 linka="/whitepaper9" title="Show Me How" style={{ marginLeft: "40%" }} />

                    </div>
                    <div className="resourceCardfORdIFFERENTcARDkv" >
                        <ResourceCard image={Res10}
                            title="Learn the best practices you need to implement to
         launch an effective lead generation campaign with a partner.
        "
                        />

                        <Button1 linka="/whitepaper10" title="Get Started" style={{ marginLeft: "40%" }} />

                    </div>
                </div>


                <div className="gridTemplateresourcesKV">
                    <div className="resourceCardfORdIFFERENTcARDkv" >
                        <ResourceCard image={Res11}
                            title="Learn about the steps you need to
         take to build content that converts."

                        />

                        <Button1 linka="/whitepaper11" title="Get Started" style={{ marginLeft: "40%" }} />

                    </div>
                    <div className="resourceCardfORdIFFERENTcARDkv" >
                        <ResourceCard image={Res12}
                            title="Learn how you can make use of big data, customer insights,
         and other market research to improve the sales closing process."

                        />

                        <Button1 linka="/whitepaper12" title="Get Started" style={{ marginLeft: "40%" }} />

                    </div>
                </div>


                <div className="gridTemplateresourcesKV">
                    <div className="resourceCardfORdIFFERENTcARDkv" >
                        <ResourceCard image={Res13}
                            title="MQLs remain relevant in today’s client acquisition process.
         Marketing is the take-off point that leads to ROI."

                        />

                        <Button1 linka="/whitepaper13" title="Find Out How !" style={{ marginLeft: "40%" }} />

                    </div>
                    <div className="resourceCardfORdIFFERENTcARDkv" >
                        <ResourceCard image={Res14}
                            title="Consider the core goals of your lead generation campaigns 
        and what you are really looking for in a partnership."
                            subtitle="Read More"
                        />

                        <Button1 linka="/whitepaper14" title="Get Idea" style={{ marginLeft: "40%" }} />

                    </div>
                </div>

                <Footer />
            </div >
        )
    }
}
