import React, { useEffect } from 'react'
import '../styles/It.css'
import map from "../images/worldmap.png"
import mapa from "../images/pie.svg"
import mapaa from "../images/coverage.svg"
import Talk from '../component/Talk'
import Footer from '../component/Footer'
import Navbar from '../component/Navbar'
export default function Bussines() {
    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])
    return (
        <div>
            <Navbar />

            <div className="wallpaper12as">
                <div className="headingwallpaper1">

                    <h1 className="h1wallpaper1">Your Source for Targeting In-market  <br />B2B Business Technology Buyers</h1>
                </div>
            </div>
            <div className="h1textaligncenter1" style={{ textTransform: "uppercase" }}>15 Million B2B Business Technology Buyers</div>

            <div className="worldmap1 mar">
                <img src={map} alt="alt" className="worldmap" />

            </div>

            <div className="worldmap1 ">
                <img src={mapa} alt="alt" className="worldmap" />

            </div>
            <div className="worldmap1 mar">
                <img src={mapaa} alt="alt" className="worldmap" />

            </div>

            <div className="talkdiv">
                <Talk heading1="Talk with one of our team members today about" heading2="Business Technology Buyer Audience." />
            </div>

            <Footer />
        </div>
    )
}
