import React from 'react'
import '../styles/Button2.css'
import { Link } from 'react-router-dom'
export default function Button({ title, style, linka }) {

    return (
        <div>
            <Link style={{ textDecoration: 'none' }} to={linka}><button className="buttonasdjas" style={style} >{title}</button></Link>
        </div>
    )
}
