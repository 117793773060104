import React, { useEffect } from 'react'
import '../styles/Talk.css'
import talk from '../images/talk.png'
import Button from './Button'

export default function Talk({ heading1, heading2 }) {
    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])
    return (
        <div>
            <div className="gridfortalk" >
                <img className="talkimage" src={talk} alt="alt" />
                <h2 className="middletalk">{heading1} <br />{heading2}</h2>
                <div className="button-class" ><Button title="Talk To Us" linka="/contact-venalicium-b2b" /></div>
            </div>
        </div>
    )
}
