import React from 'react'
import '../styles/Carousel.css'
export default function Carousel() {
    return (
        <div>
            <div className="mainCarouselBiliKV">
                <div id="locationscarouselKV">
                    <div id="first-rowcarouselKv" className="rowscArouselKVBili">
                        <div id="box" className="first-rowcarouselKv-box boxCarouselbILIkv">
                            <div className="logoCAROUSELKV"><img className="companyLogoInCarouselKV" src="https://marketplace.magento.com/media/catalog/product/cache/603f9e977a3dc35468ba3ae89ddfbb29/z/e/zendesk-logo_1.png" alt="" /></div>
                        </div>
                        <div id="box" className="first-rowcarouselKv-box boxCarouselbILIkv">
                            <div className="logoCAROUSELKV"><img className="companyLogoInCarouselKV" src="https://partners.poly.com/images/logos/poly-logo-only.svg" alt="" /></div>
                        </div>
                        <div id="box" className="first-rowcarouselKv-box boxCarouselbILIkv">
                            <div className="logoCAROUSELKV"><img className="companyLogoInCarouselKV" src="https://www.roia.org/wp-content/uploads/2017/07/Microsoft-Logo-PNG-Transparent-Image.png" alt="" /></div>
                        </div>


                        <div id="box" className="first-rowcarouselKv-box boxCarouselbILIkv class-study">
                            <div className="logoCAROUSELKV"><img className="companyLogoInCarouselKV" src="https://pngimg.com/uploads/github/github_PNG20.png" alt="" /></div>
                        </div>
                        <div id="box" className="first-rowcarouselKv-box boxCarouselbILIkv">
                            <div className="logoCAROUSELKV"><img className="companyLogoInCarouselKV" src="https://blog.savoirfairelinux.com/en-ca/wp-content/uploads/sites/2/2017/11/GitLab.png" alt="" /></div>
                        </div>
                        <div id="box" className="first-rowcarouselKv-box boxCarouselbILIkv">
                            <div className="logoCAROUSELKV"><img className="companyLogoInCarouselKV" src="https://d7umqicpi7263.cloudfront.net/img/product/03da314b-55d7-4cb1-b671-11d1e44e672d/1b9a5d35-b46c-46e5-9ebc-38248bb14028.png" alt="" /></div>
                        </div>
                        <div id="box" className="first-rowcarouselKv-box boxCarouselbILIkv">
                            <div className="logoCAROUSELKV"><img className="companyLogoInCarouselKV" src="https://www.logolynx.com/images/logolynx/49/49994dccc22f1b49b579349301896c4e.png" alt="" /></div>
                        </div>
                        <div id="box" className="first-rowcarouselKv-box boxCarouselbILIkv class-study">
                            <div className="logoCAROUSELKV"><img className="companyLogoInCarouselKV" src="https://www.leadspace.com/wp-content/uploads/2019/05/2d036d4a-5e97-11e9-8b65-c61e527ef6d0.png" alt="" /></div>
                        </div>
                        <div id="box" className="first-rowcarouselKv-box boxCarouselbILIkv">
                            <div className="logoCAROUSELKV"><img className="companyLogoInCarouselKV" src="https://i5.walmartimages.com/asr/68ba6f94-fa47-49ea-a036-6c26f896d74d_1.c25ab9232c5114dab0aca4302d08ae68.png" alt="" /></div>
                        </div>
                        <div id="box" className="first-rowcarouselKv-box boxCarouselbILIkv">
                            <div className="logoCAROUSELKV"><img className="companyLogoInCarouselKV" src="https://awsmp-logos.s3.amazonaws.com/5cb845ac-2703-42f4-a994-4c96305838df/9c0ddf8293c87db91106dbf78076626f.png" alt="" /></div>
                        </div>
                        <div id="box" className="first-rowcarouselKv-box boxCarouselbILIkv">
                            <div className="logoCAROUSELKV"><img className="companyLogoInCarouselKV" src="https://us.123rf.com/450wm/project2live/project2live2007/project2live200700472/152468631-stock-vector-mb-or-bm-abstract-monogram-vector-letter-mark-brand-fashion-sports-logo-template.jpg?ver=6" alt="" /></div>
                        </div>
                        <div id="box" className="first-rowcarouselKv-box boxCarouselbILIkv">
                            <div className="logoCAROUSELKV"><img className="companyLogoInCarouselKV" src="https://www.leadspace.com/wp-content/uploads/2019/05/riverbed-logo.png" alt="" /></div>
                        </div>
                        <div id="box" className="first-rowcarouselKv-box boxCarouselbILIkv class-study">
                            <div class="logoCAROUSELKV"><img className="companyLogoInCarouselKV" src="https://www.leadspace.com/wp-content/uploads/2019/05/symantec.png" alt="" /></div>
                        </div>
                        <div id="box" className="second-row-box boxCarouselbILIkv">
                            <div className="logoCAROUSELKV"><img className="companyLogoInCarouselKV" src="https://www.leadspace.com/wp-content/uploads/2019/05/5d138ab0-5f46-11e9-aa05-5a7a1b21483d-1.png" alt="" /></div>
                        </div>
                    </div>
                    <div id="second-row" className="rowscArouselKVBili">
                        <div id="box" className="second-row-box class-study boxCarouselbILIkv">
                            <div className="logoCAROUSELKV"><img className="companyLogoInCarouselKV" src="https://industrytoday.com/wp-content/uploads/2020/07/globalization-partners-logo.jpg" alt="" /></div>
                        </div>
                        <div id="box" className="second-row-box boxCarouselbILIkv">
                            <div className="logoCAROUSELKV"><img className="companyLogoInCarouselKV" src="https://financialit.net/sites/default/files/icertis-logo.png" alt="" /></div>
                        </div>
                        <div id="box" className="second-row-box boxCarouselbILIkv">
                            <div className="logoCAROUSELKV"><img className="companyLogoInCarouselKV" src="https://upload.wikimedia.org/wikipedia/commons/thumb/8/8f/SAP-Logo.svg/1280px-SAP-Logo.svg.png" alt="" /></div>
                        </div>
                        <div id="box" className="second-row-box boxCarouselbILIkv">
                            <div className="logoCAROUSELKV"><img className="companyLogoInCarouselKV" src="https://pngimg.com/uploads/ibm/ibm_PNG19646.png" alt="" /></div>
                        </div>
                        <div id="box" className="second-row-box boxCarouselbILIkv">
                            <div className="logoCAROUSELKV"><img className="companyLogoInCarouselKV" src="https://www.albawaba.com/sites/default/files/im/pr_new/Oracle-logo.png?width=1200&enable=upscale" alt="" /></div>
                        </div>
                        <div id="box" className="second-row-box boxCarouselbILIkv">
                            <div className="logoCAROUSELKV"><img className="companyLogoInCarouselKV" src="https://seekvectorlogo.com/wp-content/uploads/2018/01/mitel-vector-logo.png" alt="" /></div>
                        </div>
                        <div id="box" className="second-row-box boxCarouselbILIkv">
                            <div className="logoCAROUSELKV"><img className="companyLogoInCarouselKV" src="https://www.seaq.co/wp-content/uploads/2019/07/redhat-vector.png" alt="" /></div>
                        </div>
                        <div id="box" className="second-row-box boxCarouselbILIkv class-study">
                            <div className="logoCAROUSELKV"><img className="companyLogoInCarouselKV" src="https://upload.wikimedia.org/wikipedia/commons/thumb/5/5d/Sage_Group_logo.svg/1280px-Sage_Group_logo.svg.png" alt="" /></div>
                        </div>
                        <div id="box" className="second-row-box boxCarouselbILIkv">
                            <div className="logoCAROUSELKV"><img className="companyLogoInCarouselKV" src="https://www.uctoday.com/wp-content/uploads/2018/10/8x8.png" alt="" /></div>
                        </div>
                        <div id="box" className="second-row-box boxCarouselbILIkv">
                            <div className="logoCAROUSELKV"><img className="companyLogoInCarouselKV" src="" alt="" /></div>
                        </div>
                        <div id="box" className="second-row-box class-study boxCarouselbILIkv">
                            <div className="logoCAROUSELKV"><img className="companyLogoInCarouselKV" src="https://www.leadspace.com/wp-content/uploads/2019/05/Zoom-logo.png" alt="" /></div>
                        </div>
                        {/* <div id="box" className="second-row-box boxCarouselbILIkv">
                            <div className="logoCAROUSELKV"><img className="companyLogoInCarouselKV" src="https://www.leadspace.com/wp-content/uploads/2019/04/intel.png" alt="" /></div>
                        </div>
                        <div id="box" className="second-row-box boxCarouselbILIkv">
                            <div className="logoCAROUSELKV"><img className="companyLogoInCarouselKV" src="https://www.leadspace.com/wp-content/uploads/2019/05/verizon.png" alt="" /></div>
                        </div> */}
                    </div>
                    <div id="third-row" className="rowscArouselKVBili">
                        <div id="box" className="third-row-box boxCarouselbILIkv">
                            <div className="logoCAROUSELKV"><img className="companyLogoInCarouselKV" src="https://res-5.cloudinary.com/crunchbase-production/image/upload/c_lpad,f_auto,q_auto:eco/spdjvlzsahrzxujxc718" alt="" /></div>
                        </div>
                        <div id="box" className="third-row-box boxCarouselbILIkv">
                            <div className="logoCAROUSELKV"><img className="companyLogoInCarouselKV" src="https://i5.walmartimages.com/asr/68ba6f94-fa47-49ea-a036-6c26f896d74d_1.c25ab9232c5114dab0aca4302d08ae68.png" alt="" /></div>
                        </div>
                        <div id="box" className="third-row-box boxCarouselbILIkv">
                            <div className="logoCAROUSELKV"><img className="companyLogoInCarouselKV" src="https://www.mvsav.co.uk/wp-content/uploads/2019/12/Starleaf-logo.png" alt="" /></div>
                        </div>
                        <div id="box" className="third-row-box boxCarouselbILIkv">
                            <div className="logoCAROUSELKV"><img className="companyLogoInCarouselKV" src="https://upload.wikimedia.org/wikipedia/commons/2/2d/Talkdesk_Transparent_Logo.png" alt="" /></div>
                        </div>
                        <div id="box" className="third-row-box boxCarouselbILIkv">
                            <div className="logoCAROUSELKV"><img className="companyLogoInCarouselKV" src="https://cdn.freelogovectors.net/wp-content/uploads/2020/03/freshdesk-logo.png" alt="" /></div>
                        </div>
                        <div id="box" className="third-row-box boxCarouselbILIkv">
                            <div className="logoCAROUSELKV"><img className="companyLogoInCarouselKV" src="https://s3-eu-west-1.amazonaws.com/tpd/logos/587e3e750000ff00059af430/0x0.png" alt="" /></div>
                        </div>
                        <div id="box" className="third-row-box boxCarouselbILIkv class-study">
                            <div className="logoCAROUSELKV"><img className="companyLogoInCarouselKV" src="https://www.exclusive-networks.com/asia-uk/wp-content/uploads/sites/19/2017/11/solarwind.png" alt="" /></div>
                        </div>
                        <div id="box" className="third-row-box boxCarouselbILIkv">
                            <div className="logoCAROUSELKV"><img className="companyLogoInCarouselKV" src="https://vr-plugin.com/wp-content/uploads/2018/06/adsk-500x500.png" alt="" /></div>
                        </div>
                        <div id="box" className="third-row-box boxCarouselbILIkv">
                            <div className="logoCAROUSELKV"><img className="companyLogoInCarouselKV" src="https://7signal.com/wp-content/uploads/2019/07/servicenow-logo.png" alt="" /></div>
                        </div>
                        <div id="box" className="third-row-box boxCarouselbILIkv">
                            <div className="logoCAROUSELKV"><img className="companyLogoInCarouselKV" src="https://prnewswire2-a.akamaihd.net/p/1893751/sp/189375100/thumbnail/entry_id/0_mn4tkb4i/def_height/1680/def_width/1680/version/100012/type/2/q/100" alt="" /></div>
                        </div>
                        <div id="box" className="third-row-box boxCarouselbILIkv">
                            <div className="logoCAROUSELKV"><img className="companyLogoInCarouselKV" src="https://www.leadspace.com/wp-content/uploads/2019/04/RingCentral-logo.jpeg" alt="" /></div>
                        </div>
                        <div id="box" className="third-row-box boxCarouselbILIkv">
                            <div className="logoCAROUSELKV"><img className="companyLogoInCarouselKV" src="https://www.leadspace.com/wp-content/uploads/2019/05/onelogin1.png" alt="" /></div>
                        </div>
                        <div id="box" className="third-row-box boxCarouselbILIkv">
                            <div className="logoCAROUSELKV"><img className="companyLogoInCarouselKV" src="https://www.leadspace.com/wp-content/uploads/2019/05/e03677b2NVIDIA.jpeg" alt="" /></div>
                        </div>
                        <div id="box" className="third-row-box boxCarouselbILIkv">
                            <div className="logoCAROUSELKV"><img className="companyLogoInCarouselKV" src="https://www.leadspace.com/wp-content/uploads/2019/04/n3-.png" alt="" /></div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
