import React, { useEffect } from 'react'
import '../styles/Leadgeneration.css'
import tablet from '../images/tablet.png'
import Talk from '../component/Talk'
import Button from '../component/Button'
import marketing from '../images/marketing.png'
import account from '../images/account.png'
import funnel from '../images/funnel.png'
import map from "../images/worldmap.png"
import ItImage from '../images/IT@2x.png'
import SalesImage from '../images/Sales icon@2x.png'
import HrImage from '../images/HR@10x.png'
import MarketingImage from '../images/Marketing@2x.png'
import FinanceImage from '../images/Finance@10x.png'
import BusinessImage from '../images/buisiness@10x.png'
import CardSmall from '../component/CardSmall'
import Footer from '../component/Footer'
import Navbar from '../component/Navbar'


export default function Leadgeneration() {
    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])
    return (
        <div>
            <Navbar />
            <div className="wallpaper1as" >
                <div className="headingwallpaper1">
                    <h1 className="h2wallpaper1 animate__animated animate__backInLeft animate__delay-.5s animate__duration-2s" >FLEXIBLE SOLUTIONS FOR EVERY NEED</h1>
                    <h1 className="h1wallpaper1 animate__animated animate__backInRight animate__delay-.5s animate__duration-2s">B2B Lead Generation Solutions</h1>
                </div>
            </div>
            <div className="h1textaligncenter" data-aos="fade-up">Lead Generation Like Nobody's Business</div>
            <div className="h1textaligncenteras" data-aos="fade-up">
                We create and provide solutions customized according to your needs and expectations,
                be it at any stage of marketing! We help in targeting the smallest of spots which often go unnoticed.
                Since lead generation is all about buyers, we can design an approach which is buyer-centric
                and can provide you with the pace and success of sales and marketing that you have planned!
                Want to know more about our lead generation services? Scroll some more.
               </div>
            <div className="talkdiv" >
                <Talk heading1="Take Your B2B Content " heading2="Syndication to the Next Level." />
            </div>
            <div className="leftgrid1 bow " >
                <div className="leftside1" data-aos="fade-up">
                    <h2 className="lefthead">Venalicium Syndication: Content Syndication With Intent Data</h2>
                    <p className="leftpara">
                        We target and involve the right buyers which can help you reach your potential-buyers
                        through a vast range of channels. Thus, ensuring a better reach and wider range of productivity.
                         </p>
                    <div className="marginforbutton">
                        <Button linka="/lead-generation-services/content-syndication-lead-generation" title="Learn About B2BSyndication" style={{ width: "50%" }} />
                    </div>
                </div>

                <div className="imggrid1" >
                    <img src={tablet} alt="alt" className="imggrid1" data-aos="fade-up" />
                </div>
            </div>
            {/* <div className="content2part2lgas">
                <div>
                    <h1 className="h1part2as">SMG Broke into <br />New Markets</h1>
                    <h5 className="parah5as">Expansive filtering capabilities and intent-activity data layering empower SMG to better forecast lead generation budgets while maintaining a consistent stream of quality leads into its marketing funnel.</h5>
                    <Button title="V3Syndication: Smg Case Study " style={{ width: "75%" }} />
                </div>
                <div>
                    <h2 className="h2part2as">“We at SMG love the superb customer service and quality leads we get from V3B2B. Jeff and team are proactively involved in our campaigns and making sure we get the highest quality leads relevant to our industries. Given our niche industry, it is not always easy to find leads with great titles that match our buyer personas, but V3B2B finds a way!”</h2>
                    <p className="authorlgas">Jared Emmitt,<br />Manager, Demand Generation at SMG</p>

                </div>
            </div>
            <div className="content2part2lgas">
                <div>
                    <h1 className="h1part2as">Lead Generation <br />While we Sleep</h1>
                    <h5 className="parah5as">Powered by the largest intent-driven audience in the space, PureSyndication + ABM reached and engaged with the right decision makers within MX’s target ABM list.</h5>
                    <Button title="V3Syndication: Smg Case Study " style={{ width: "75%" }} />
                </div>
                <div>
                    <h2 className="h2part2as">“The team at PureB2B has set up a process that allows us to ‘set and forget’ so that we constantly have leads flowing into our marketing automation system. Their team has always been responsive to our questions and quick to get new campaigns setup and going. They are also willing to segment campaigns with different content and lists so that we can maximize our value.”</h2>
                    <p className="authorlgas">Mike Wallgren,<br />Director of Marketing Operations and Digital Marketing at MX</p>

                </div>
            </div> */}
            <div className="gridleftimage bor ">
                <div className="imagecontainer">
                    <img className="imageingrid" src={marketing} alt="alt" data-aos="fade-up" />
                </div>
                <div className="paragraphdiv " data-aos="fade-up" >
                    <div>
                        <h1 className="h1part1as">Venalicium ABM: Account-Based Programmatic Display Management</h1>
                        <p className="paradetails">
                            Here we provide a concise and integrated approach with amazingly
                            unique designs of advertisement which enables you to reach the broadest
                            community of buyers. This also helps in targeting the existing demands and
                            also reach out to new change-makers!
                              </p>
                        <div className="marginforbutton">
                            <Button linka="/lead-generation-services/abm-lead-generation" title="Learn More About B2BABM " style={{ width: "50%" }} />
                        </div>
                    </div>
                </div>
            </div>
            <div className="gridleftimage bor ">
                <div className="paragraphdiv" data-aos="fade-up">
                    <div>
                        <h1 className="h1part1as">Venalicium Trends: B2B Account Intelligence </h1>
                        <p className="paradetails">
                            Surveys show us a clearer picture of the recent industry trends on the finest
                            of levels if analyzed properly. We provide you access to the surveys and the
                            information of the latest leaders of
                            the market specific to the industry you belong to,
                            future purchase-plans, expansion ideas etc
                         </p>
                        <div className="marginforbutton">
                            <Button linka="/lead-generation-services/content-syndication-lead-generation" title="Learn About B2BSyndication" style={{ width: "50%" }} />
                        </div>
                    </div>
                </div>
                <div className="imagecontainer" >
                    <img className="imageingrid" src={account} alt="alt" data-aos="fade-up" />
                </div>

            </div>
            <div className="gridleftimage bor ">
                <div className="paragraphdiv" data-aos="fade-up">
                    <div>
                        <h1 className="h1part1as">Venalicium Funnel: Integrated Turn-Key Market Development </h1>
                        <p className="paradetails">

                            Here we provide the lead generation for down-funnel
                            marketing, helping the clients look upon and resolve those
                            hard-hits that they faced during the generation of leads for the bottom-of-funnels
                            stage of marketing. Hence, saving a whole lot of time, investment, resources and efforts.

                           </p>
                        <div className="marginforbutton">
                            <Button linka="/lead-generation-services/b2B-lead-generation-funnel-bottom-of-funnel" title="Learn About B2BFunnel" style={{ width: "50%" }} />
                        </div>
                    </div>
                </div>
                <div className="imagecontainer" data-aos="fade-up">
                    <img className="imageingrid" src={funnel} alt="alt" />
                </div>

            </div>
            <div className="talkdiv">
                <Talk heading1="Take Your B2B Content " heading2="Syndication to the Next Level." />
            </div>
            <div className="h1textaligncenter1" data-aos="fade-up" style={{ textTransform: "uppercase" }}>THE VENALICIUM B2B GLOBAL AUDIENCE OF MORE THAN 125 MILLION TECHNOLOGY BUYERS</div>
            <div className="smallCrdInMarketKV">
                <CardSmall
                    linka="/technology-buyers/it-audience"
                    title="IT"
                    image={ItImage} />

                <CardSmall
                    linka="/technology-buyers/sales-audience"
                    title="Sales"
                    image={SalesImage} />

                <CardSmall
                    linka="/technology-buyers/hr-audience"
                    title="HR"
                    image={HrImage} />

                <CardSmall
                    linka="/technology-buyers/business-audience"
                    title="Business"
                    image={BusinessImage} />

                <CardSmall
                    linka="/technology-buyers/finance-audience"
                    title="Finance"
                    image={FinanceImage} />

                <CardSmall
                    linka="/technology-buyers/marketing-audience"
                    title="Marketing"
                    image={MarketingImage} />
            </div>
            <div className="worldmap1" data-aos="zoom-in" >
                <img src={map} alt="alt" className="worldmap" />
            </div>
            <Footer />
        </div>
    )
}
