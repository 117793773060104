import React from 'react'
import '../styles/Card.css'

export default function Card({ image, title, subtitle, subtitle1, subtitle2 }) {

    return (
        <div>
            <div className="cardvenaliciumas" data-aos="flip-right">
                <img className="cardimgas" src={image} alt="alt" />
                <h1 className="cardh1jsas">{title}</h1>
                <h3 className="cardh3jsas">{subtitle}</h3>
                <h3 className="cardh3jsas">{subtitle1}</h3>
                <h3 className="cardh3jsas">{subtitle2}</h3>
            </div>
        </div>
    )
}
