import React from 'react'
import '../styles/Button.css'
import { Link } from 'react-router-dom'
export default function Button({ title, style, linka,type }) {

    return (
        <div>
            <Link style={{ textDecoration: 'none' }} to={linka}><button type={type} className="buttonas" style={style} >{title}</button></Link>
        </div>
    )
}
