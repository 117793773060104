import React, { useEffect } from 'react'
import '../styles/About.css'
import Redcard1 from '../component/Redcard1'
import Button2 from '../component/Button2'
import map from "../images/worldmap.png"
import Talk from '../component/Talk'
import Footer from '../component/Footer'
import Navbar from '../component/Navbar'
export default function About() {
    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])
    return (
        <div>
            <Navbar />
            <div className="wallpaper17as">
                <h1 className="paatanii animate__animated animate__backInRight animate__delay-1s animate__duration-2s" >About Us</h1>
                <h1 className="h4abc animate__animated animate__backInLeft animate__delay-.5s animate__duration-2s" >Our growing ecosystem of Data, Technology and Marketing Services reaches our customers with the best enterprise Marketing and Sales solutions.</h1>

            </div>
            <div className="headimgcarddjas mar">Our Partners</div>
            <div className="gridforpartners">
                <div className="abcxyz">
                    <img data-aos-once="true" data-aos="fade-right" data-aos-easing="linear" className="partneraslogo" src="https://marketplace.magento.com/media/catalog/product/cache/603f9e977a3dc35468ba3ae89ddfbb29/z/e/zendesk-logo_1.png" alt="" />
                    <img data-aos-once="true" data-aos="fade-right" data-aos-easing="linear" data-aos-delay="200" className="partneraslogo" src="https://partners.poly.com/images/logos/poly-logo-only.svg" alt="" />
                    <img data-aos-once="true" data-aos="fade-right" data-aos-easing="linear" data-aos-delay="400" className="partneraslogo" src="https://www.roia.org/wp-content/uploads/2017/07/Microsoft-Logo-PNG-Transparent-Image.png" alt="" />
                    <img data-aos-once="true" data-aos="fade-right" data-aos-easing="linear" data-aos-delay="600" className="partneraslogo" src="https://pngimg.com/uploads/github/github_PNG20.png" alt="" />
                    <img data-aos-once="true" data-aos="fade-right" data-aos-easing="linear" data-aos-delay="800" className="partneraslogo" src="https://blog.savoirfairelinux.com/en-ca/wp-content/uploads/sites/2/2017/11/GitLab.png" alt="" />

                </div>
                <div className="abcxyz">
                    <img data-aos-once="true" data-aos="fade-right" data-aos-easing="linear" className="partneraslogo" src="https://www.logolynx.com/images/logolynx/49/49994dccc22f1b49b579349301896c4e.png" alt="" />
                    <img data-aos-once="true" data-aos="fade-right" data-aos-easing="linear" data-aos-delay="200" className="partneraslogo" src="https://i5.walmartimages.com/asr/68ba6f94-fa47-49ea-a036-6c26f896d74d_1.c25ab9232c5114dab0aca4302d08ae68.png" alt="" />
                    <img data-aos-once="true" data-aos="fade-right" data-aos-easing="linear" data-aos-delay="400" className="partneraslogo" src="https://us.123rf.com/450wm/project2live/project2live2007/project2live200700472/152468631-stock-vector-mb-or-bm-abstract-monogram-vector-letter-mark-brand-fashion-sports-logo-template.jpg?ver=6" alt="" />
                    <img data-aos-once="true" data-aos="fade-right" data-aos-easing="linear" data-aos-delay="600" className="partneraslogo" src="https://awsmp-logos.s3.amazonaws.com/5cb845ac-2703-42f4-a994-4c96305838df/9c0ddf8293c87db91106dbf78076626f.png" alt="" />
                    <img data-aos-once="true" data-aos="fade-right" data-aos-easing="linear" data-aos-delay="800" className="partneraslogo" src="https://industrytoday.com/wp-content/uploads/2020/07/globalization-partners-logo.jpg" alt="" />



                </div>
                <div className="abcxyz">
                    <img data-aos-once="true" data-aos="fade-right" data-aos-easing="linear" className="partneraslogo" src="https://upload.wikimedia.org/wikipedia/commons/thumb/8/8f/SAP-Logo.svg/1280px-SAP-Logo.svg.png" alt="" />
                    <img data-aos-once="true" data-aos="fade-right" data-aos-easing="linear" data-aos-delay="200" className="partneraslogo" src="https://pngimg.com/uploads/ibm/ibm_PNG19646.png" alt="" />
                    <img data-aos-once="true" data-aos="fade-right" data-aos-easing="linear" data-aos-delay="400" className="partneraslogo" src="https://www.albawaba.com/sites/default/files/im/pr_new/Oracle-logo.png?width=1200&enable=upscale" alt="" />
                    <img data-aos-once="true" data-aos="fade-right" data-aos-easing="linear" data-aos-delay="600" className="partneraslogo" src="https://seekvectorlogo.com/wp-content/uploads/2018/01/mitel-vector-logo.png" alt="" />
                    <img data-aos-once="true" data-aos="fade-right" data-aos-easing="linear" data-aos-delay="800" className="partneraslogo" src="https://www.seaq.co/wp-content/uploads/2019/07/redhat-vector.png" alt="" />

                </div>
                <div className="abcxyz">
                    <img data-aos-once="true" data-aos="fade-right" data-aos-easing="linear" className="partneraslogo" src="https://www.uctoday.com/wp-content/uploads/2018/10/8x8.png" alt="" />
                    <img data-aos-once="true" data-aos="fade-right" data-aos-easing="linear" data-aos-delay="200" className="partneraslogo" src="https://res-5.cloudinary.com/crunchbase-production/image/upload/c_lpad,f_auto,q_auto:eco/spdjvlzsahrzxujxc718" alt="" />
                    <img data-aos-once="true" data-aos="fade-right" data-aos-easing="linear" data-aos-delay="400" className="partneraslogo" src="https://i5.walmartimages.com/asr/68ba6f94-fa47-49ea-a036-6c26f896d74d_1.c25ab9232c5114dab0aca4302d08ae68.png" alt="" />
                    <img data-aos-once="true" data-aos="fade-right" data-aos-easing="linear" data-aos-delay="600" className="partneraslogo" src="https://www.mvsav.co.uk/wp-content/uploads/2019/12/Starleaf-logo.png" alt="" />
                    <img data-aos-once="true" data-aos="fade-right" data-aos-easing="linear" data-aos-delay="800" className="partneraslogo" src="https://upload.wikimedia.org/wikipedia/commons/2/2d/Talkdesk_Transparent_Logo.png" alt="" />

                </div>
                <div className="abcxyz">
                    <img data-aos-once="true" data-aos="fade-right" data-aos-easing="linear" className="partneraslogo" src="https://s3-eu-west-1.amazonaws.com/tpd/logos/587e3e750000ff00059af430/0x0.png" alt="" />
                    <img data-aos-once="true" data-aos="fade-right" data-aos-easing="linear" data-aos-delay="200" className="partneraslogo" src="https://www.exclusive-networks.com/asia-uk/wp-content/uploads/sites/19/2017/11/solarwind.png" alt="" />
                    <img data-aos-once="true" data-aos="fade-right" data-aos-easing="linear" data-aos-delay="400" className="partneraslogo" src="https://vr-plugin.com/wp-content/uploads/2018/06/adsk-500x500.png" alt="" />
                    <img data-aos-once="true" data-aos="fade-right" data-aos-easing="linear" data-aos-delay="600" className="partneraslogo" src="https://7signal.com/wp-content/uploads/2019/07/servicenow-logo.png" alt="" />
                    <img data-aos-once="true" data-aos="fade-right" data-aos-easing="linear" data-aos-delay="800" className="partneraslogo" src="https://cdn.freelogovectors.net/wp-content/uploads/2020/03/freshdesk-logo.png" alt="" />

                </div>
                <div className="abcxyz">
                    <img data-aos-once="true" data-aos="fade-right" data-aos-easing="linear" className="partneraslogo" src="https://d7umqicpi7263.cloudfront.net/img/product/03da314b-55d7-4cb1-b671-11d1e44e672d/1b9a5d35-b46c-46e5-9ebc-38248bb14028.png" alt="" />
                    <img data-aos-once="true" data-aos="fade-right" data-aos-easing="linear" data-aos-delay="200" className="partneraslogo" src="https://financialit.net/sites/default/files/icertis-logo.png" alt="" />
                    <img data-aos-once="true" data-aos="fade-right" data-aos-easing="linear" data-aos-delay="400" className="partneraslogo" src="https://upload.wikimedia.org/wikipedia/commons/thumb/5/5d/Sage_Group_logo.svg/1280px-Sage_Group_logo.svg.png" alt="" />
                    <img data-aos-once="true" data-aos="fade-right" data-aos-easing="linear" data-aos-delay="600" className="partneraslogo" src="https://prnewswire2-a.akamaihd.net/p/1893751/sp/189375100/thumbnail/entry_id/0_mn4tkb4i/def_height/1680/def_width/1680/version/100012/type/2/q/100" alt="" />
                    <img data-aos-once="true" data-aos="fade-right" data-aos-easing="linear" data-aos-delay="800" className="partneraslogo" src="https://www.exclusive-networks.com/asia-uk/wp-content/uploads/sites/19/2017/11/solarwind.png" alt="" />

                </div>
            </div>
            <div className="greyas">
                <div className="amrit">
                    <div className="headimgcarddjas ">Our Partner Benefits</div>
                    <div className="headimgcarddjasdjas mar">Our industry-leading partnerships ensure our customers get the most out of their investments, by driving increased revenue.</div>
                    <div className="cardgridfortraget">
                        <div className="felxdj">
                            <Redcard1 title="Know" subtitle3="All the data you need to understand your customers - accurate and in real-time, in a single place." />
                        </div>
                        <div className="felxdj">
                            <Redcard1 title="Target" subtitle3="Artificial Intelligence (AI) turns that data into clear next step recommendations, including who to target, how and when." />
                        </div>
                        <div className="felxdj"><Redcard1 title="Activate" subtitle3="Turnkey integrations into Sales, Marketing and Digital Ad platforms for omni-channel execution and alignment." />
                        </div></div>

                    <Button2 title="Our Coustmers" linka="/contact-venalicium-b2b" style={{ textAlign: "Center", marginLeft: "43%" }} />

                </div>
                <div className="worldmap1 mar" data-aos="zoom-in" >
                    <img src={map} alt="alt" className="worldmap" />
                </div>
                <div className="talkdiv  ">
                    <Talk heading1="Take Your B2B Content " heading2="Syndication to the Next Level." />
                </div>
            </div>
            <Footer />
        </div>
    )
}
