import React, { useEffect } from 'react'
import '../styles/Audience.css'
import map from "../images/worldmap.png"


import CardSmall from '../component/Smallcard2'
import logo from '../images/log.PNG'
import Button from '../component/Button'
import Button1 from '../component/Button1'
import Button2 from '../component/Button2'
import Talk from '../component/Talk'
import Footer from '../component/Footer'
import Navbar from '../component/Navbar'

export default function V3b2b() {
    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])
    return (
        <div>
               <Navbar />
            <div className="wallpaper10as">
                <div className="headingwallpaper1">

                    <h1 className="h1wallpaper1" data-aos="zoom-in">
                    A Business Professionals' Rendezvous 
                     

</h1>
                </div>
            </div>
            <div className="h1textaligncenter1" data-aos="fade-up" style={{ textTransform: "capitalize",fontWeight:"400" }}>The Venalicium B2B Global Audience Of More Than 125 Million Technology Buyers <br/>
            
            </div>
            <div className="smallCrdInMarketKV">
                <CardSmall
                    linka="https://www.v3webinar.com"
                    title="V3 Webinar"
                    image={"https://www.v3webinar.com/images/logo.png"} />

                <CardSmall
                    linka="https://www.v3media.online"
                    title="V3 Media"
                    image={"https://v3media.online/wp-content/uploads/2020/07/cropped-a6bd9fcc-c639-413a-81c9-ae05366748c4ko.jpg"} />

                <CardSmall
                    linka="https://www.v3techmedia.online"
                    title="V3 TechMedia"
                    image={"https://v3finmedia.online/wp-content/uploads/2020/07/cropped-a6bd9fcc-c639-413a-81c9-ae05366748c4ko.jpg"} />

                <CardSmall
                    linka="https://www.v3finmedia.online"
                    title="V3 FinMedia"
                    image={"https://v3finmedia.online/wp-content/uploads/2020/07/cropped-a6bd9fcc-c639-413a-81c9-ae05366748c4ko.jpg"} />

                <CardSmall
                    linka="https://www.v3hrmedia.online"
                    title="V3 HrMedia"
                    image={"https://v3finmedia.online/wp-content/uploads/2020/07/cropped-a6bd9fcc-c639-413a-81c9-ae05366748c4ko.jpg"} />

                <CardSmall
                    linka="https://www.venaliciumb2b.com"
                    title="Venalicium Digital"
                    image={"https://www.venaliciumb2b.com/static/media/venalogo.651eac5e.PNG"} />
            </div>
            <div className="worldmap1">
                <img src={map} alt="alt" className="worldmap" data-aos="zoom-in" />
            </div>
            <div className="h1textaligncenter">Venalicium B2B Demand Generation Solutions</div>
            <div className="h1textaligncenteras">Leveraging innovative tactics crafted through years of research and testing, Venalicium B2B works across its exclusive network of more than 125 million in-market technology buyers to provide demand generation solutions at every step of the marketing and sales process.</div>
            <div className="logogroup ">
                <div className="logogrid"><img className="logonavbarasdj" src={logo} alt="alt" />
                    <h4 className="h3logofirstas">Lead Generation</h4>
                    <Button linka="/lead-generation-services" title="Learn More!" />
                </div>
                <div className="logogrid"><img className="logonavbarasdj" src={logo} alt="alt" />
                    <h4 className="h3logomiddleas">Content Creation</h4>
                    <Button1 linka="/content-creation-solutions" title="Learn More!" />
                </div>
                <div className="logogrid"><img className="logonavbarasdj" src={logo} alt="alt" />
                    <div className="kislayisbeep">

                        <h4 className="h3logolastas">Lead Development</h4>
                    </div>
                    <Button2 linka="/lead-total-development-solutions" title="Learn More!" />
                </div>
            </div>
            <div className="talkdiv">
                <Talk heading1="Talk with one of our team members " heading2="today about your target audience." />
            </div>
            <div className="finalgrid">
                <div className="formargindiv">
                    <div className="paragraphdiv">
                        <div>
                        <h1 className="h1part1as">Take your Sales and Marketing Initiatives to the Global Level</h1>
                        <p className="paradetails">Our clients rely on us to accelerate their
                        bottom-line initiatives on a global scale. Why
                        fight your way to the head of the pack, when
                        you can go straight to the lead source?

                 </p>
                        {/* <div className="marginforbutton">
                            <Button title="How SideCar Found Success" style={{ width: "70%" }} />
                        </div> */}
                    </div>
                    </div>
                </div>

                <div className="backgrid"></div>
            </div>
            <Footer />
        </div>
    )
}
