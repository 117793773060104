import React, { useEffect } from 'react'
import '../styles/WhitePaper.css'
import map from "../images/worldmap.png"
import ItImage from '../images/IT@2x.png'
import SalesImage from '../images/Sales icon@2x.png'
import HrImage from '../images/HR@10x.png'
import MarketingImage from '../images/Marketing@2x.png'
import FinanceImage from '../images/Finance@10x.png'
import BusinessImage from '../images/buisiness@10x.png'
import CardSmall from '../component/CardSmall'
import Footer from '../component/Footer'
export default function WhitePaper1() {

    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])
    return (
        <div>

            <div className="mainDivContainerImageHeader">
                <div>
                    <h1 className="headerofContainerHeaderH1KV">White Paper:<br />
                Expert Lead Generation Strategies for Driving MQLs
                </h1>
                </div>
            </div>


            <div className="gridCategoryheymaincontainerKV">
                <div>
                    <div className="divofcontentwhitePaperKV">
                        When sales teams have quality leads to work with, they can focus their efforts on a smaller list of
                        qualified leads that could actually bring results making everything more efficient and streamlined.
</div>
                    <div className="divofcontentwhitePaperKV">
                        Every company has a different set of guidelines on what they consider an MQL,
                        so it's good to test a combination of strategies to see what best suites your needs.
                        What might work for your company may not work for another,
                        so it is always important to have your team’s dynamics in mind.
</div>
                    <div className="divofcontentwhitePaperKV">
                        Check out these best practices to kick-start your MQL strategy!
</div>


                </div>
                <div className="contactDownloadFormKV">
                    <form action="https://formsubmit.co/kislayvats22@gmail.com" method="POST">
                        <input type="hidden" name="_next" value="https://bili.co.in/" />
                        <div className="gridname">
                            <div className="but aas">

                                <input required type="text" className="aaa" name="first_name" id="name" placeholder="First Name" />
                            </div>
                            <div className="but aas">

                                <input required type="text" className="aaa" name="Last_name" id="name" placeholder=" Last Name" />
                            </div>
                        </div>
                        <div className="but">

                            <input required type="email" id="mail" className="aaa" name="email" placeholder="Email Address" />

                        </div>

                        <div className="but">

                            <input required type="text" className="aaa" name="company-name" id="msg" placeholder="Company Name" />
                        </div>
                        <div className="buted">
                            <input required className="aab" id="captcha" type="checkbox" name="_captcha" value="true" />
                            <label for="captcha" className="labelas" >I'm not a robot</label>
                            {/* <img src="https://upload.wikimedia.org/wikipedia/commons/thumb/a/ad/RecaptchaLogo.svg/1200px-RecaptchaLogo.svg.png"
                                    alt="alt" className="asdfgh" /> */}
                        </div>
                        <div className="butuon">

                            <button type="submit" className=" button btn">Download Now</button>
                            <input type="hidden" name="_captcha" value="false" />

                        </div>

                    </form>
                </div>
            </div>

            <div className="h1textaligncenter1" style={{ textTransform: "uppercase" }}>THE VENALICIUMB2B GLOBAL AUDIENCE OF MORE THAN 125 MILLION TECHNOLOGY BUYERS</div>
            <div className="smallCrdInMarketKV">
                <CardSmall
                    title="IT"
                    image={ItImage} />

                <CardSmall
                    title="Sales"
                    image={SalesImage} />

                <CardSmall
                    title="HR"
                    image={HrImage} />

                <CardSmall
                    title="Business"
                    image={BusinessImage} />

                <CardSmall
                    title="Finance"
                    image={FinanceImage} />

                <CardSmall
                    title="Marketing"
                    image={MarketingImage} />
            </div>
            <div className="worldmap1">
                <img src={map} alt="alt" className="worldmap" />
            </div>
            <Footer />

        </div>



    )
}

