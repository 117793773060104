import React from "react";
import "../styles/Footer.css";

import { Link } from "react-router-dom";

// import logop from '../images/venalogo.PNG'

export default function Footer() {
  return (
    <div className="footeras">
      <div className="footer-content">
        <div className="logofas">
          <div className="marginboott">
            <h2 className="h3fas">THINK OUT OF THE BOX</h2>
            <h4
              className="h4fas"
              style={{
                fontSize: "13px",
                fontWeight: "300",
                color: "#ffffffc7",
              }}
            >
              Join 350,000+ fellow marketers to receive a summary of the latest
              marketing tips from across the world directly to your inbox once a
              week.
            </h4>
          </div>

          <form
            action="https://formsubmit.co/info@v3media.online"
            method="POST"
          >
            <input
              type="hidden"
              name="_next"
              value="https://v3media.online/wp-content/uploads/2022/04/venaMediakit.pdf"
            />
            <div className="meraman">
              <i
                className="fa fa-envelope djas "
                style={{ marginRight: "5px", marginLeft: "10px" }}
                aria-hidden="true"
              />
              <input
                className="inputas"
                type="email"
                name="email"
                placeholder="Email"
              />
            </div>
            <div className="meraman">
              <i
                className="fa fa-user djas "
                style={{ marginRight: "5px", marginLeft: "10px" }}
                aria-hidden="true"
              />
              <input
                className="inputas"
                type="text"
                name="name"
                placeholder=" Your Name"
              />
            </div>
            <div className="meraman">
              <i
                className="fa fa-user djas "
                style={{ marginRight: "5px", marginLeft: "10px" }}
                aria-hidden="true"
              />
              <input
                className="inputas"
                type="text"
                name="comapany"
                placeholder="Company Name"
              />
            </div>
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <button
                type="submit"
                style={{ cursor: "pointer" }}
                className="buttonstylebc"
              >
                Download Media Kit
              </button>
              <input type="hidden" name="_captcha" value="false" />
              <input type="hidden" name="_cc" value="venaliciumb2b@gmail.com" />
            </div>
          </form>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            {/* <Link to="/"><img className="logonavbaras" src={logop} alt="alt" /></Link> */}
            <a
              style={{ textDecoration: "none", color: "white" }}
              href="https://www.facebook.com/Venalicium-Digital-LLC-108040230680979/?modal=admin_todo_tour"
            >
              <i className="fab fa-facebook dj" />
            </a>
            <a
              style={{ textDecoration: "none", color: "white" }}
              href="https://twitter.com/VenaliciumL"
            >
              {" "}
              <i className="fab fa-twitter dj" />
            </a>
            <a
              style={{ textDecoration: "none", color: "white" }}
              href="https://www.linkedin.com/company/14510829/admin/"
            >
              {" "}
              <i className="fab fa-linkedin dj" />
            </a>
          </div>
        </div>

        <div className="leadgeneratefas">
          <h2 className="h3fas">WHO WE ARE</h2>
          <h4
            className="h4fas"
            style={{ fontSize: "13px", fontWeight: "300", color: "#ffffffc7" }}
          >
            V3 Media is a digital marketing and media company that provides
            brand awareness and lead generation services worldwide
          </h4>
          <h4
            className="h4fas"
            style={{ fontSize: "13px", fontWeight: "300", color: "#ffffffc7" }}
          >
            The V3 Media team consists of over 300 marketing specialists, data
            science experts, and martech engineers that execute and orchestrate
            over 2,800 campaigns each year.
          </h4>
          <h2 className="h3fas">WHAT WE DO</h2>
          <h4
            className="h4fas"
            style={{ fontSize: "13px", fontWeight: "300", color: "#ffffffc7" }}
          >
            V3 Media provides performance-based marketing services by
            orchestrating multi-channel campaigns across email, web, mobile,
            voice, social, search, and display.
          </h4>
          <h4
            className="h4fas"
            style={{ fontSize: "13px", fontWeight: "300", color: "#ffffffc7" }}
          >
            Brands, agencies, platforms and publishers rely on V3 Media for lead
            acquisition at scale. Marketers from large enterprises to small and
            mid-sized companies use V3 Media experts for their marketing
            programs.
          </h4>
        </div>
        {/* <div className="privacyfas">
                    <h2 className="h3fas">Venalicium B2B</h2>
                    <h4 className="h4fas"><Link to="/contact-venalicium-b2b" className="linkfas">Contact Us</Link></h4>
                    <h4 className="h4fas"><Link to="/aboutus-lead-generation-services" className="linkfas">About Us</Link></h4>
                </div> */}

        <div className="knowusfas">
          <h2 className="h3fas">Audience</h2>
          <h4 className="h4fas">
            <Link to="/technology-buyers/business-audience" className="linkfas">
              Business
            </Link>
          </h4>
          <h4 className="h4fas">
            <Link to="/technology-buyers/it-audience" className="linkfas">
              IT
            </Link>
          </h4>
          <h4 className="h4fas">
            <Link to="/technology-buyers/sales-audience" className="linkfas">
              Sales
            </Link>
          </h4>
          <h4 className="h4fas">
            <Link to="/technology-buyers/finance-audience" className="linkfas">
              Finance
            </Link>
          </h4>
          <h4 className="h4fas">
            <Link to="/technology-buyers/hr-audience" className="linkfas">
              Human Resource
            </Link>
          </h4>
          <h4 className="h4fas">
            <Link to="/technology-buyers/business-audience" className="linkfas">
              Resources
            </Link>
          </h4>
        </div>
        <div className="contentleadfas">
          <div>
            <h2 className="h3fas">Our Publication Site</h2>
            <h4 className="h4fas">
              <a href="https://www.v3media.online" className="linkfas">
                Marketing Professionals
              </a>
            </h4>
            <h4 className="h4fas">
              <a href="https://www.v3techmedia.online" className="linkfas">
                Technology Professionals
              </a>
            </h4>
            <h4 className="h4fas">
              <a href="https://www.v3finmedia.online" className="linkfas">
                Finance Professionals
              </a>
            </h4>
            <h4 className="h4fas">
              <a href="https://www.v3hrmedia.online" className="linkfas">
                HR Professionals
              </a>
            </h4>
          </div>
          <div className="leadgeneratefas">
            <h2 className="h3fas">Our Webinar Promotion </h2>
            <h4 className="h4fas">
              <a href="https://www.v3webinar.com" className="linkfas">
                V3 Webinar
              </a>
            </h4>
          </div>
        </div>
        <div className="knowusfas">
          <h2 className="h3fas">Quick Links</h2>
          <h4 className="h4fas">
            <Link to="/lead-generation-services" className="linkfas">
              Lead Generation
            </Link>
          </h4>
          <h4 className="h4fas">
            <Link to="/content-creation-solutions" className="linkfas">
              Content Creation
            </Link>
          </h4>
          <h4 className="h4fas">
            <Link to="/lead-total-development-solutions" className="linkfas">
              Lead Development
            </Link>
          </h4>
          <h4 className="h4fas">
            <Link to="/technology-buyers" className="linkfas">
              Audience
            </Link>
          </h4>
          <h4 className="h4fas">
            <Link to="/aboutus-lead-generation-services" className="linkfas">
              About Us
            </Link>
          </h4>
          <h4 className="h4fas">
            <Link to="/contact-venalicium-b2b" className="linkfas">
              Contact Us
            </Link>
          </h4>
        </div>

        {/* <div className="subscribefas">

                    <div>


                    </div>
                </div> */}
      </div>
      <div className="footerbottomas">
        <div>
          <h4 className="h4fas2">
            Design | Developed And Maintained by{" "}
            <a href="https://bili.co.in" className="linkfasb">
              Bili
            </a>
            .
          </h4>
        </div>
        <div>
          {" "}
          <h4 className="h4fas2">
            &copy;<script>document.write(new Date().getFullYear());</script> All
            Rights Reserved {new Date().getFullYear()}.
          </h4>
        </div>
      </div>
    </div>
  );
}
