import React, { useEffect } from 'react'
import '../styles/InMarketLeads.css'
import dok from '../images/doyouknow.png'
import option from '../images/optional.png'
import brands from '../images/brands.png'
import fixed from '../images/leadfixed.png'

import Footer from '../component/Footer'
import Button from '../component/Button'
import Card from '../component/Card'
import Talk from '../component/Talk'

import ItImage from '../images/IT@2x.png'
import SalesImage from '../images/Sales icon@2x.png'
import HrImage from '../images/HR@10x.png'
import MarketingImage from '../images/Marketing@2x.png'
import FinanceImage from '../images/Finance@10x.png'
import BusinessImage from '../images/buisiness@10x.png'
import CardSmall from '../component/CardSmall'
import Navbar from '../component/Navbar'


export default function InMarketLeads() {
    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])
    return (
        <div>
            <Navbar />
            <div className="inmarketas">
                <h2 className="h2imlas" data-aos="fade-up-right">V3TRENDS:</h2>
                <h1 className="h1imlas" data-aos="fade-up-left">B2B Account <br />Intelligence</h1>
                <h5 className="h5imlas" data-aos="fade-up-left">Discover In-Market Leads Looking to Purchase<br /> within the Next 12 Months</h5>
            </div>
            <div className="containerimlas">
                <div className="part2imlgridas">
                    <div className="gridpart1imlas">
                        <img className="imgimlpart1as" src={dok} alt="alt" data-aos="zoom-in-right" />
                    </div>
                    <div className="gridpart2imlas " data-aos="zoom-in-left">
                        <h1 className="h1abmpart1as">Do You Know Who is In-Market for Your Solution?</h1>
                        <p className="paraimlas">It is common for sales and marketing resources to be expended nurturing or prospecting a technology decision maker who may not have any interest in a solution, or worse, no plans or budget to make a new technology purchase.
                        <br />To avoid this issue, you need to work with a partner who knows your target markets inside and out, and can discover in-market leads for you.
</p>
                        <Button linka="/contact-venalicium-b2b" title="Contact Us" style={{ marginLeft: "50px" }} />
                    </div>
                </div>
            </div>
            <div className="identityas">
                <div className="containerimlas">
                    <div className="gridforimlas">
                        <div className="gridpart3imlas" data-aos="zoom-in-right">
                            <h1 className="h1abmpart1as">Do You Know Who is In-Market for Your Solution?</h1>
                            <p className="parapart2imlas">It is common for sales and marketing resources to be expended nurturing or prospecting a technology decision maker who may not have any interest in a solution, or worse, no plans or budget to make a new technology purchase.
                        <br />To avoid this issue, you need to work with a partner who knows your target markets inside and out, and can discover in-market leads for you.
</p>
                            <Button linka="/contact-venalicium-b2b" title="Contact Us" style={{ marginLeft: "50px" }} />
                        </div>
                        <div></div>
                    </div>
                </div>
            </div>

            <div className="containerimlas">
                <h1 className="h1abmpart1as">Benefits of V3Trends</h1>
                <div className="forgridas">
                    <div className="felxdj">
                        <Card image={option} title="Optional Exclusivity" />
                    </div>
                    <div className="felxdj">
                        <Card image={brands} title="Our Brands" subtitle="HR Trends, IT Management Trends," subtitle1="Sales Technology Trends, IT SecurityTrends," subtitle2=" Business Software Trends, Marketing Technology Trends" />
                    </div>
                    <div className="felxdj">
                        <Card image={fixed} title="Fixed Lead Pricing" />
                    </div>
                </div>
            </div>
            <div className="displaynone">
                <div className="smallCrdInMarketKV">
                    <CardSmall
                        linka="/technology-buyers/it-audience"
                        title="IT"
                        image={ItImage} />

                    <CardSmall
                        linka="/technology-buyers/sales-audience"
                        title="Sales"
                        image={SalesImage} />

                    <CardSmall
                        linka="/technology-buyers/hr-audience"
                        title="HR"
                        image={HrImage} />

                    <CardSmall
                        linka="/technology-buyers/business-audience"
                        title="Business"
                        image={BusinessImage} />

                    <CardSmall
                        linka="/technology-buyers/finance-audience"
                        title="Finance"
                        image={FinanceImage} />

                    <CardSmall
                        linka="/technology-buyers/marketing-audience"
                        title="Marketing"
                        image={MarketingImage} />
                </div>
            </div>
            <div className="talkdiv">
                <Talk heading1="Accelerate Your Sales & Marketing  " heading2="Pipeline  with VenaliciumTrends Today!" />
            </div>


            <Footer />
        </div>
    )
}
