import React, { useEffect } from 'react'
import '../styles/Fullservice.css'
import content from '../images/contentdiv.png'
import Button from '../component/Button'
import graph from '../images/sticker.png'
import map from "../images/worldmap.png"
import ItImage from '../images/IT@2x.png'
import SalesImage from '../images/Sales icon@2x.png'
import HrImage from '../images/HR@10x.png'
import MarketingImage from '../images/Marketing@2x.png'
import FinanceImage from '../images/Finance@10x.png'
import BusinessImage from '../images/buisiness@10x.png'
import CardSmall from '../component/CardSmall'
import Talk from '../component/Talk'

import Footer from '../component/Footer'
import Navbar from '../component/Navbar'

export default function Fullservice() {
    useEffect(() => {
        window.scrollTo(0, 0)
      }, [])
    return (
        <div>
        <Navbar />
            <div className="wallpaper6as">
                <div className="headingwallpaper">
                    <h1 className="h2wallpaper">V3 CONTENT:</h1>
                    <h1 className="h1wallpaper">B2B Content  <br />that Converts</h1>
                </div>
            </div>
            <div className="margintopddaa">
                <div className="gridleftimage  ">
                    <div className="imagecontainer">
                        <img className="imageingrid" src={content} alt="alt" />
                    </div>
                    <div className="paragraphdiv " >
                        <div>
                        <h1 className="h1part1as">Is Your Content Relevant to Your Target Personas?</h1>
                        <p className="paradetails1">
                        Target buyers need relevant content. Many companies struggle with 
                        designing a bunch of quality content which can serve this purpose.
                         There needs to be a consistent flow of content to keep your brand 
                         and product awareness apt and also to reach your target personas.
                         </p>
                        <p className="paradetails1">
                        You would stand out in this competitive market with correct content 
                        marketing strategies when you work with a demand-generation content provider,
                         that understands your industry, your needs and your plans. How about that?
                         </p>
                        <div className="marginforbutton">
                            <Button linka="/contact-venalicium-b2b " title="Contact Us " style={{ width: "30%" }} />
                        </div>
                        </div>
                    </div>

                </div>
            </div>
            <div className="intermediate2">
                <div className="felxdjas"> 
                    <div>
                    <div className="lefthead2">Optimize Your Marketing Strategy with Research-Driven Content</div>
                    <div className="leftpara2">Working across our global network of more than 125 million technology buyers, our researchers utilize custom surveys to dive deep into the challenges and needs of the decision makers within your target personas.</div>
                    <div className="leftpara2">From this research, our team of content strategists create highly targeted and relevant content in the format of your choice.</div>
                    <div className="leftpara2">Speak with a team member to discuss your Content Creation goals.
                    
                    <div className="marginforbutton">
                        <Button linka="/contact-venalicium-b2b" title="Contact Us Today" />
                    </div>
                    </div>
                    </div>

                    </div>
                    <div></div>
                </div>
                <div className="h1textaligncenter1" style={{ textTransform: "uppercase" }}>Let Venalicium B2B Do the Content Production Heavy Lifting</div>
                <div className="imggraphics">
                    <img className="imggraphics1" src={graph} alt="alt" />
                </div>
                <div className="margintopddaaa">
                <div className="paragraphdiv" style={{textAlign:"center"}}>
                    <div>
                    <h1 className="h1part1as">Venalicium Funnel: Integrated Turn-Key Market Development </h1>
                    <p className="paradetails">Venalicium Funnel provides down-funnel outbound lead generation to help clients address the roadblocks they’ve hit generating bottom-of-funnel leads. The cost-effective process saves you time and resources spent nurturing and delivers BoFu leads that are already engaged on the buyer’s journey. </p>
                    <div className="marginforbutton">
                        <Button linka="/contact-venalicium-b2b" title="See All Topics" style={{ marginLeft:"35%" }} />
                    </div>
                    </div>
                </div>
                </div>
                <div className="h1textaligncenter1" style={{ textTransform: "uppercase" }}>THE VENALICIUM B2B GLOBAL AUDIENCE OF MORE THAN 125 MILLION TECHNOLOGY BUYERS</div>
            <div className="smallCrdInMarketKV">
                <CardSmall
                linka="/technology-buyers/it-audience"
                    title="IT"
                    image={ItImage} />

                <CardSmall
                linka="/technology-buyers/sales-audience"
                    title="Sales"
                    image={SalesImage} />

                <CardSmall
                 linka="/technology-buyers/hr-audience"
                    title="HR"
                    image={HrImage} />

                <CardSmall
                 linka="/technology-buyers/business-audience"
                    title="Business"
                    image={BusinessImage} />

                <CardSmall
                 linka="/technology-buyers/finance-audience"
                    title="Finance"
                    image={FinanceImage} />

                <CardSmall
                 linka="/technology-buyers/marketing-audience"
                    title="Marketing"
                    image={MarketingImage} />
            </div>
            <div className="worldmap1">
                <img src={map} alt="alt" className="worldmap" />
            </div>
            <div className="talkdiv">
                <Talk heading1="Ready to talk  " heading2="Content Creation?" />
            </div>
            <div className="containerimlas">

</div>
    <Footer />
    
    
    </div>
            


                
   
      
            
    )
}
