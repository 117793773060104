import React, { Component } from 'react'
import '../styles/WhitePaper.css'
import map from "../images/worldmap.png"
import ItImage from '../images/IT@2x.png'
import SalesImage from '../images/Sales icon@2x.png'
import HrImage from '../images/HR@10x.png'
import MarketingImage from '../images/Marketing@2x.png'
import FinanceImage from '../images/Finance@10x.png'
import BusinessImage from '../images/buisiness@10x.png'
import CardSmall from '../component/CardSmall'
import Footer from '../component/Footer'
export default class WhitePaper8 extends Component {
    componentDidMount() {
        window.scrollTo(0, 0);
    }
    render() {

        return (
            <div>

                <div className="mainDivContainerImageHeader">
                    <div>
                        <h1 className="headerofContainerHeaderH1KV">White Paper:<br />
                Complete Guide to Data-Driven Marketing Strategy
         </h1>
                    </div>
                </div>


                <div className="gridCategoryheymaincontainerKV">
                    <div>
                        <div className="divofcontentwhitePaperKV">

                            Data-driven marketing is about responding to customer needs promptly,
                            based on buying behavior and data collected both on and offline. These insights can be
                            used for building better relationships,
                            promoting customer loyalty, and improving customer lifetime value.
</div>
                        <div className="divofcontentwhitePaperKV">
                            The right data will give you valuable insights for building customer relationships and sales prospecting.
                            It’s all about personalizing the buyer’s journey to enhance the overall customer experience.
                            After all, isn’t that what marketing is all about?
</div>
                        <div className="divofcontentwhitePaperKV">
                            Lacking internal infrastructure to collect and utilize this data can place your organization at a
                            competitive disadvantage, but before you dive into the deep end of the data-lake,
                            it is imperative that you establish best practices for collection and interpretation.
</div>
                        <div className="divofcontentwhitePaperKV">
                            Download this free resource today and learn how you can enhance your marketing processes
                            with the right data collection and analysis.
    </div>
                    </div>

                    <div className="contactDownloadFormKV">
                        <form action="https://formsubmit.co/kislayvats22@gmail.com" method="POST">
                            <input type="hidden" name="_next" value="https://bili.co.in/" />
                            <div className="gridname">
                                <div className="but aas">

                                    <input required type="text" className="aaa" name="first_name" id="name" placeholder="First Name" />
                                </div>
                                <div className="but aas">

                                    <input required type="text" className="aaa" name="Last_name" id="name" placeholder=" Last Name" />
                                </div>
                            </div>
                            <div className="but">

                                <input required type="email" id="mail" className="aaa" name="email" placeholder="Email Address" />

                            </div>

                            <div className="but">

                                <input required type="text" className="aaa" name="company-name" id="msg" placeholder="Company Name" />
                            </div>
                            <div className="buted">
                                <input required className="aab" id="captcha" type="checkbox" name="_captcha" value="true" />
                                <label for="captcha" className="labelas" >I'm not a robot</label>
                                {/* <img src="https://upload.wikimedia.org/wikipedia/commons/thumb/a/ad/RecaptchaLogo.svg/1200px-RecaptchaLogo.svg.png"
                                    alt="alt" className="asdfgh" /> */}
                            </div>
                            <div className="butuon">

                                <button type="submit" className=" button btn">Download Now</button>
                                <input type="hidden" name="_captcha" value="false" />

                            </div>

                        </form>
                    </div>
                </div>

                <div className="h1textaligncenter1" style={{ textTransform: "uppercase" }}>THE VENALICIUMB2B GLOBAL AUDIENCE OF MORE THAN 125 MILLION TECHNOLOGY BUYERS</div>
                <div className="smallCrdInMarketKV">
                    <CardSmall
                        title="IT"
                        image={ItImage} />

                    <CardSmall
                        title="Sales"
                        image={SalesImage} />

                    <CardSmall
                        title="HR"
                        image={HrImage} />

                    <CardSmall
                        title="Business"
                        image={BusinessImage} />

                    <CardSmall
                        title="Finance"
                        image={FinanceImage} />

                    <CardSmall
                        title="Marketing"
                        image={MarketingImage} />
                </div>
                <div className="worldmap1">
                    <img src={map} alt="alt" className="worldmap" />
                </div>
                <Footer />
            </div>



        )
    }
}
