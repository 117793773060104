import React, { useEffect } from 'react'
import '../styles/AccountBasedMarketing.css'
import your from '../images/your.png'
import Button from '../component/Button'
import Footer from '../component/Footer'
import Card from '../component/Card'
import multi from '../images/multi.png'
import abm from '../images/abm.png'
import tact from '../images/tactical.png'
import talk from '../images/talk.png'
import Navbar from '../component/Navbar'


export default function AccountBasedMarketing() {
    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])
    return (
        <div>
            <Navbar />
            <div className="divabmas">
                <div className="headabmas">
                    <h1 className="h2abmas " data-aos="fade-up">V3ABM:</h1>
                    <h1 className="h1abmas " data-aos="fade-up">B2B Account-Based<br /> Marketing Lead<br /> Generation</h1>
                </div>
                <div className="containerabmas">
                    <div className="gridabmas">
                        <div className="contentabmas">
                            <img className="imgcnabmas" src={your} alt="alt" data-aos="fade-up" />
                        </div>
                        <div className="textabmpart1as" data-aos="fade-up">
                            <h1 className="h1imlpart1as">Is your Account-Based Marketing Strategy Fully Optimized?</h1>
                            <p className="paraabmas">Our lead generation solutions create customized campaigns micro-targeted to your in-market buyers at any stage of the buying cycle.
                            V3B2B's hands-on approach and buyer-intent data provide you with the sales and marketing pipeline velocity you need.
                            Learn about our lead generation services below.</p>
                            <Button linka="/contact-venalicium-b2b" title="Contact Us" style={{ marginLeft: "50px" }} />
                        </div>
                    </div>

                </div>
                <div className="divincreaseabmas">
                    <div className="contentabmas">
                        <div className="fakegridabmas" data-aos="zoom-in">
                            <h1 className="h1abmpart2as">
                                Increase Sales & Marketing<br /> ROI When Targeting Key<br /> Accounts
                </h1><div className="paraabmpart2asdj">
                                <p className="paraabmpart2as">Concentrate sales and marketing resources on a defined set of<br /> target accounts and employ personalized campaigns designed<br /> to resonate with each.</p>
                                <p className="paraabmpart2as">Powered by the largest intent-driven audience in the space, <br /> VenalicumABM reaches and engages with the right decision makers <br />within your target ABM list. Identify and engage with buyers<br /> across multiple departments and business units in one place.</p>
                                <p className="paraabmpart2as">Speak with a team member to discuss your Account-Based<br /> Marketing goals.</p>
                            </div>
                            <Button linka="/contact-venalicium-b2b" title="CONTACT US TODAY" style={{ marginLeft: "100px" }} />
                        </div>
                    </div>
                </div>
                <div className="abmpart2containeras">
                    <h1 className="h1abmpart3as" data-aos="flip-up" >The Power of  V3ABM</h1>
                    <div className="cardgridas">
                        <div className="felxdj">
                            <Card image={multi} title="Multi-Channel Engagement" subtitle="Digital, email, and telephon outreach " subtitle1="targeting  your accounts through our" subtitle2=" content syndication network." />
                        </div>
                        <div className="felxdj">
                            <Card image={tact} title="Tactical Account Targeting" subtitle="Digital, email, and telephon outreach " subtitle1="targeting  your accounts through our" subtitle2=" content syndication network." />
                        </div>
                        <div className="felxdj"> <Card image={abm} title="ABM List Intent Data" subtitle="Build a custom ABM list or strengthen " subtitle1="your own from our global audience of" subtitle2=" content syndication network." />
                        </div></div>

                    <div className="contentabmpart1as">
                        <img className="imgcnabmasdj" src={talk} alt="alt" data-aos="fade-up" />

                        <h2 className="abmh22as" data-aos="fade-up">Ready to Accelerate Your <br />ABM Strategy?</h2>

                        <div className="felxdj " data-aos="fade-up">

                            <Button linka="/contact-venalicium-b2b" title="CONTACT US TODAY" />

                        </div>
                    </div>
                </div>
                <Footer />
            </div>
        </div>
    )
}
