import React, { useEffect } from 'react'
import '../styles/Redcard.css'

export default function Redcard({ title, subtitle }) {
    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])
    return (
        <div className="redcardbody" data-aos="flip-left">

            <div className="redborder">
                <div className="titleclass" >{title}</div>
            </div>
            <div className="middleclass">
                {subtitle}
            </div>




            <div className="lastdiv"></div>
        </div>

    )
}
