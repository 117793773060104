import React, { Component } from 'react'
import '../styles/WhitePaper.css'
import map from "../images/worldmap.png"
import ItImage from '../images/IT@2x.png'
import SalesImage from '../images/Sales icon@2x.png'
import HrImage from '../images/HR@10x.png'
import MarketingImage from '../images/Marketing@2x.png'
import FinanceImage from '../images/Finance@10x.png'
import BusinessImage from '../images/buisiness@10x.png'
import CardSmall from '../component/CardSmall'
import Footer from '../component/Footer'
export default class WhitePaper5 extends Component {
    componentDidMount() {
        window.scrollTo(0, 0);
    }
    render() {

        return (
            <div>

                <div className="mainDivContainerImageHeader">
                    <div>
                        <h1 className="headerofContainerHeaderH1KV">White Paper:<br />
                The Rise of Account-Based Marketing: Essentials to Success

         </h1>
                    </div>
                </div>


                <div className="gridCategoryheymaincontainerKV">
                    <div>
                        <div className="divofcontentwhitePaperKV">

                            Align your sales and marketing team to develop a successful Account-Based Marketing (ABM)
                            strategy and increase company ROI.


</div>
                        <div className="divofcontentwhitePaperKV">
                            Account-based marketing is best applied in B2B marketing as it treats each customer as an
                            individual market with their own set of campaigns,
                            content and events to keep them in your company’s sales cycle.
</div>
                        <div className="divofcontentwhitePaperKV">
                            So instead of your marketing team treating the industry as a whole, ABM creates
                            an opportunity for your company to speak directly to your audience and allow your message
                            to resonate with prospects and clients personally.
</div>
                        <div className="divofcontentwhitePaperKV">
                            Thankfully, tools and technologies now exist to make ABM affordable and more efficient,
                            helping companies apply this technique on a greater scale.
    </div>
                        <div className="divofcontentwhitePaperKV">
                            Get this white paper now to accelerate your ABM program!
    </div>
                    </div>

                    <div className="contactDownloadFormKV">
                        <form action="https://formsubmit.co/kislayvats22@gmail.com" method="POST">
                            <input type="hidden" name="_next" value="https://bili.co.in/" />
                            <div className="gridname">
                                <div className="but aas">

                                    <input required type="text" className="aaa" name="first_name" id="name" placeholder="First Name" />
                                </div>
                                <div className="but aas">

                                    <input required type="text" className="aaa" name="Last_name" id="name" placeholder=" Last Name" />
                                </div>
                            </div>
                            <div className="but">

                                <input required type="email" id="mail" className="aaa" name="email" placeholder="Email Address" />

                            </div>

                            <div className="but">

                                <input required type="text" className="aaa" name="company-name" id="msg" placeholder="Company Name" />
                            </div>
                            <div className="buted">
                                <input required className="aab" id="captcha" type="checkbox" name="_captcha" value="true" />
                                <label for="captcha" className="labelas" >I'm not a robot</label>
                                {/* <img src="https://upload.wikimedia.org/wikipedia/commons/thumb/a/ad/RecaptchaLogo.svg/1200px-RecaptchaLogo.svg.png"
                                    alt="alt" className="asdfgh" /> */}
                            </div>
                            <div className="butuon">

                                <button type="submit" className=" button btn">Download Now</button>
                                <input type="hidden" name="_captcha" value="false" />

                            </div>

                        </form>
                    </div>
                </div>


                <div className="h1textaligncenter1" style={{ textTransform: "uppercase" }}>THE VENALICIUMB2B GLOBAL AUDIENCE OF MORE THAN 125 MILLION TECHNOLOGY BUYERS</div>
                <div className="smallCrdInMarketKV">
                    <CardSmall
                        title="IT"
                        image={ItImage} />

                    <CardSmall
                        title="Sales"
                        image={SalesImage} />

                    <CardSmall
                        title="HR"
                        image={HrImage} />

                    <CardSmall
                        title="Business"
                        image={BusinessImage} />

                    <CardSmall
                        title="Finance"
                        image={FinanceImage} />

                    <CardSmall
                        title="Marketing"
                        image={MarketingImage} />
                </div>
                <div className="worldmap1">
                    <img src={map} alt="alt" className="worldmap" />
                </div>
                <Footer />

            </div>



        )
    }
}
