import React, { useEffect } from 'react'
import '../styles/Landing.css'
import logo from '../images/log.PNG'
import Carousel from '../component/Carousel'
import Button1 from '../component/Button1'
import Button from '../component/Button'
import Button2 from '../component/Button2'
// import Circle from '../component/Circle'
import frame from '../images/lapyhome.svg'
import Footer from '../component/Footer'
import Talk from '../component/Talk'
import Typical from 'react-typical'
import cir from '../images/logopng.jpg'
import { Link } from 'react-router-dom'
// import Funnel from '../component/Funnel'
import promo from '../images/funnelp.jpg'
import Navbar from '../component/Navbar'
import Funnel from '../component/Funnel'








export default function Landing() {
    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])
    return (
        <div>   <Navbar />
            <div className="whole">
                <div className="landingcoverKV" >

                    <h1 className="h1hKV" data-aos="flip-down">

                        <span className="topper">Global AI Based Demand Generation<br />With {' '}</span>
                        <span className="typicalword">
                            <Typical
                                loop={Infinity}

                                wrapper="B"
                                steps={[
                                    'AI Driven Digital Marketing Services',
                                    1000,
                                    'AI Driven Strategic Consulting',
                                    1000,
                                    'AI Driven B2B Marketing Solution',
                                    1000

                                ]}
                            />
                        </span>
                        <br />
                        <span className="chonesize">From The Leading Global AI Driven B2B Marketer.</span>
                    </h1>
                    <div className="buttonh1as">
                        <Button1 linka="/contact-venalicium-b2b" title="Contact Us" style={{ fontSize: "20px" }} />
                    </div>
                </div>


                <div className="h1textaligncenter1" data-aos="fade-up" style={{ textTransform: "capitalize", letterSpacing: "2px", marginTop: "5%" }}>B2B Demand generation</div>
                <div className="gridforblub">
                    <div className="circlepath">

                        <Link style={{ textDecoration: "none", fontSize: "20px", fontWeight: "500", color: "black" }} to="/lead-total-development-solutions" ><div className="movingupdown" data-aos-once="true" data-aos="zoom-in">Data Research & Development</div></Link>
                        <Link style={{ textDecoration: "none", fontSize: "20px", fontWeight: "500", color: "black" }} to="/lead-generation-services" ><div className="movingupdown" data-aos-once="true" data-aos="zoom-in">Lead Generation</div></Link>

                        <Link style={{ textDecoration: "none", fontSize: "20px", fontWeight: "500", color: "black" }} to="/content-creation-solutions"><div className="movingupdown2" data-aos-once="true" data-aos="zoom-in">Content Creation</div></Link>
                        <Link style={{ textDecoration: "none", fontSize: "20px", fontWeight: "500", color: "black" }} to="/technology-buyers"><div className="movingupdown3" data-aos-once="true" data-aos="zoom-in">Market Research</div></Link>
                    </div>

                    <div className="flexforv " style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>

                        <Funnel />
                        <img src={promo} className="promo" alt="b2b" />
                    </div>
                    <div className="circlepath">
                        <Link style={{ textDecoration: "none", fontSize: "20px", fontWeight: "500", color: "black", zIndex: "30" }} to="/lead-total-development-solutions"><div className="movingupdown4" data-aos-once="true" data-aos="zoom-in">Survey Based Lead Generation</div></Link>
                        <Link style={{ textDecoration: "none", fontSize: "20px", fontWeight: "500", color: "black", zIndex: "30" }} to="/lead-generation-services/b2b-lead-generation-trends-inmarket-leads"> <div className="movingupdown5" data-aos-once="true" data-aos="zoom-in" >Account Intelligence</div></Link>
                        <Link style={{ textDecoration: "none", fontSize: "20px", fontWeight: "500", color: "black", zIndex: "30" }} to="/lead-total-development-solutions"> <div className="movingupdown6" data-aos-once="true" data-aos="zoom-in">Lead Development</div></Link>
                        <Link style={{ textDecoration: "none", fontSize: "20px", fontWeight: "500", color: "black", zIndex: "30" }} to="/content-creation-solutions" data-aos-once="true" data-aos="zoom-in"><div className="movingupdown7">Custom Sales Services</div></Link>
                    </div>
                </div>
                <div className="h1textaligncenter1" data-aos-anchor-placement="top-center" data-aos="fade-up" style={{ textTransform: "capitalize", letterSpacing: "2px" }}>Drive true Customer Obsession for</div>
                <div className="logogroup ">
                    <div className="logogrid"><img className="logonavbarasdj" src={logo} alt="alt" />
                        <h4 className="h3logofirstas">Lead Generation</h4>
                        <Button linka="/lead-generation-services" title="Learn More!" />
                    </div>
                    <div className="logogrid"><img className="logonavbarasdj" src={logo} alt="alt" />
                        <h4 className="h3logomiddleas">Content Creation</h4>
                        <Button1 linka="/content-creation-solutions" title="Learn More!" />
                    </div>
                    <div className="logogrid"><img className="logonavbarasdj" src={logo} alt="alt" />
                        <div>

                            <h4 className="h3logolastas">Lead Development</h4>
                        </div>
                        <Button2 linka="/lead-total-development-solutions" title="Learn More!" />
                    </div>
                </div>
                <div className="h1textaligncenter1" data-aos-anchor-placement="top-center" data-aos="fade-up" style={{ textTransform: "capitalize", letterSpacing: "2px", marginTop: "5%" }}>Venalicium B2B Global Audiences</div>

                <div className=" daspal">

                    <img src={cir} alt="alt" className="imgsrccircle" />
                </div>
                <div>
                    <img src={frame} alt="alt" className="framerac" data-aos="zoom-in" />
                </div>
                <div className="greyas">
                    <div className="headimgcarddjas">Leading B2B Brands Trust<br />Venalicium</div>
                    <div className="buttongreendj">
                        <Button2 linka="/aboutus-lead-generation-services" title="Our Coustmers" style={{ textAlign: "Center" }} />
                    </div>
                    <Carousel />
                    <div className="nodisplay">
                        <div className="talkdiv">
                            <Talk heading1="Take Your B2B Content " heading2="Syndication to the Next Level." />
                        </div>
                    </div>
                </div>
                <Footer />
            </div >
        </div >
    )
}
