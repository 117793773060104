import React, { Component } from 'react'
import '../styles/WhitePaper.css'
import map from "../images/worldmap.png"
import ItImage from '../images/IT@2x.png'
import SalesImage from '../images/Sales icon@2x.png'
import HrImage from '../images/HR@10x.png'
import MarketingImage from '../images/Marketing@2x.png'
import FinanceImage from '../images/Finance@10x.png'
import BusinessImage from '../images/buisiness@10x.png'
import CardSmall from '../component/CardSmall'
import Footer from '../component/Footer'
export default class WhitePaper1 extends Component {
    componentDidMount() {
        window.scrollTo(0, 0);
    }
    render() {

        return (
            <div>

                <div className="mainDivContainerImageHeader">
                    <div>
                        <h1 className="headerofContainerHeaderH1KV">White Paper:<br />
                How Marketers are Tackling Sales Pipeline Ownership
         </h1>
                    </div>
                </div>


                <div className="gridCategoryheymaincontainerKV">
                    <div>
                        <div className="divofcontentwhitePaperKV">

                            Potential clients have so many touchpoints, marketing must evolve their strategies to provide their
                            prospects with the solutions they need to convert to paying clients. For pipeline marketing to see success,
                            sales and marketing need to be aligned. This ensures the prospect, sales, and marketing are on the same page
                            through the buyer’s journey.
</div>
                        <div className="divofcontentwhitePaperKV">
                            This is where pipeline management becomes important. The core of delivering a
                            lucrative pipeline strategy is for the marketing and sales team to have the same end goal:
                            close the deal and get a good ROI on their resources and efforts. This means marketing must employ a tactical lead
                            generation and management strategy to allow marketing initiatives to turn to revenue for the company.
</div>
                        <div className="divofcontentwhitePaperKV">
                            Check out this white paper to accelerate your pipeline marketing!
</div>

                    </div>

                    <div className="contactDownloadFormKV">
                        <form action="https://formsubmit.co/kislayvats22@gmail.com" method="POST">
                            <input type="hidden" name="_next" value="https://bili.co.in/" />
                            <div className="gridname">
                                <div className="but aas">

                                    <input required type="text" className="aaa" name="first_name" id="name" placeholder="First Name" />
                                </div>
                                <div className="but aas">

                                    <input required type="text" className="aaa" name="Last_name" id="name" placeholder=" Last Name" />
                                </div>
                            </div>
                            <div className="but">

                                <input required type="email" id="mail" className="aaa" name="email" placeholder="Email Address" />

                            </div>

                            <div className="but">

                                <input required type="text" className="aaa" name="company-name" id="msg" placeholder="Company Name" />
                            </div>
                            <div className="buted">
                                <input required className="aab" id="captcha" type="checkbox" name="_captcha" value="true" />
                                <label for="captcha" className="labelas" >I'm not a robot</label>
                                {/* <img src="https://upload.wikimedia.org/wikipedia/commons/thumb/a/ad/RecaptchaLogo.svg/1200px-RecaptchaLogo.svg.png"
                                    alt="alt" className="asdfgh" /> */}
                            </div>
                            <div className="butuon">

                                <button type="submit" className=" button btn">Download Now</button>
                                <input type="hidden" name="_captcha" value="false" />

                            </div>

                        </form>
                    </div>
                </div>


                <div className="h1textaligncenter1" style={{ textTransform: "uppercase" }}>THE VENALICIUMB2B GLOBAL AUDIENCE OF MORE THAN 125 MILLION TECHNOLOGY BUYERS</div>
                <div className="smallCrdInMarketKV">
                    <CardSmall
                        title="IT"
                        image={ItImage} />

                    <CardSmall
                        title="Sales"
                        image={SalesImage} />

                    <CardSmall
                        title="HR"
                        image={HrImage} />

                    <CardSmall
                        title="Business"
                        image={BusinessImage} />

                    <CardSmall
                        title="Finance"
                        image={FinanceImage} />

                    <CardSmall
                        title="Marketing"
                        image={MarketingImage} />
                </div>
                <div className="worldmap1">
                    <img src={map} alt="alt" className="worldmap" />
                </div>
                <Footer />

            </div>



        )
    }
}
