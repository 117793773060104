import React, { Component } from 'react'
import '../styles/Navbar.css'
import { Link } from 'react-router-dom';

import ItImage from '../images/IT@2x.png'
import SalesImage from '../images/Sales icon@2x.png'
import HrImage from '../images/HR@10x.png'
import MarketingImage from '../images/Marketing@2x.png'
import FinanceImage from '../images/Finance@10x.png'
import BusinessImage from '../images/buisiness@10x.png'
import logop from '../images/log.PNG'
import Button from './Button';
import Card from './Cardsmall1';
export default class Navbar extends Component {

  constructor(props) {
    super(props);

    this.listener = null;
    this.state = {
      status: "top",

    }

  }

  componentDidMount() {
    this.listener = document.addEventListener("scroll", e => {
      var scrolled = document.scrollingElement.scrollTop;
      if (scrolled >= 120) {
        if (this.state.status !== "amir") {
          this.setState({ status: "amir" });
        }
      } else {
        if (this.state.status !== "top") {
          this.setState({ status: "top" });
        }
      }
    });
  }

  componentDidUpdate() {
    document.removeEventListener("scroll", this.listener);
  }
  burgerToggle(){
		let linksEl = document.querySelector('.narrowLinks');
		if (linksEl.style.display === 'block') {
			linksEl.style.display = 'none';
		} else {
			linksEl.style.display = 'block';
		}
	} 
  burgerToggleadd(){
		let linksEl = document.querySelector('.narrowLinksadd');
		if (linksEl.style.display === 'block') {
			linksEl.style.display = 'none';
		} else {
			linksEl.style.display = 'block';
		}
	} 
  burgerToggleadd1(){
		let linksEl = document.querySelector('.narrowLinksadd1');
		if (linksEl.style.display === 'block') {
			linksEl.style.display = 'none';
		} else {
			linksEl.style.display = 'block';
		}
	} 
  burgerToggleadd2(){
  
    let linksE2 = document.querySelector('.narrowLinksadd2');
 
		if (linksE2.style.display === 'block') {
			linksE2.style.display = 'none';
		} else {
      linksE2.style.display = 'block';
      
		}
	} 
  burgerToggleadd3(){
		let linksEl = document.querySelector('.narrowLinksadd3');
		if (linksEl.style.display === 'block') {
			linksEl.style.display = 'none';
		} else {
			linksEl.style.display = 'block';
		}
	} 
  burgerToggleadd4(){
		let linksEl = document.querySelector('.narrowLinksadd4');
		if (linksEl.style.display === 'block') {
			linksEl.style.display = 'none';
		} else {
			linksEl.style.display = 'block';
		}
	} 
  burgerToggleadd5(){
		let linksEl = document.querySelector('.narrowLinksadd5');
		if (linksEl.style.display === 'block') {
			linksEl.style.display = 'none';
		} else {
			linksEl.style.display = 'block';
		}
	} 
  render() {
  

    return (
      <div>
     

        <div className="wrapper"  style={{
          backgroundColor: this.state.status === "top" ? "transparent" : "white",
          color: this.state.status === "top" ? "white" : "#888888",
          position: "fixed"
        }}>
          <div className="mega_menu" >
          <Link to="/"><img className="logonavbaras" src={logop} alt="alt" /></Link>
            <ul className="megagridas">
            

              <li className="navbarlias"><Link to="/lead-generation-services"  style={{ color: this.state.status === "top" ? "white" : "#888888" }} >Lead Generation
            
             </Link>
             <div className="sub_menu" style={{
                  backgroundColor: this.state.status === "top" ? "white" : "white",
                  color: this.state.status === "top" ? "white" : "#888888",
                  position: "fixed"}} >
                    <h1 className="navbarh1as" style={{color:"#1f85de" ,textAlign:"left"}}>B2B Lead Generation</h1>
                  <div className="submenugridas">
                 <div className="part1gridas">
                 <Link to="/"><img src={logop} alt="alt" className="logofor" /></Link>
                 </div>
                 <div className="part1gridas"> 
                 <Link to="/lead-generation-services"> <h2 className="navbarh2as">Top-of-Funnel</h2></Link>
                  <div className="margintopnavas">
                  <Link to="/lead-generation-services/content-syndication-lead-generation"><h5 className="navbarh5as">Content Syndication</h5></Link>
                  <Link to="/lead-generation-services/content-syndication-lead-generation"> <h5 className="navbarh5as">Leads with Intent Data</h5></Link>
                  <Link to="/lead-generation-services/content-syndication-lead-generation"><h5 className="navbarh5as">Opt-in Database</h5></Link>
                   </div>
                   </div>
                 <div className="part1gridas">
                 <Link to="/lead-generation-services/abm-lead-generation"><h2 className="navbarh2as">Account Based Marketing</h2></Link>
                 <div className="margintopnavas">
                 <Link to="/lead-generation-services/content-syndication-lead-generation"><h5 className="navbarh5as">ABM Content Syndication</h5></Link>
                 <Link to="/lead-generation-services/content-syndication-lead-generation"><h5 className="navbarh5as">Intent Driven ABM</h5></Link>
                 </div></div>
                 <div className="part1grid2as">
                 <Link to="/lead-total-development-solutions"> <h2 className="navbarh2as">In-Market Opportunities</h2></Link>
                 <div className="margintopnavas">
                 <Link to="/lead-generation-services/venalicium-b2b-lead-generation-funnel"><h5 className="navbarh5as">I Need Meetings</h5></Link>
                   <Link to="/lead-generation-services/b2b-lead-generation-trends-inmarket-leads"><h5 className="navbarh5as">In-Market Leads</h5></Link>
                   <Link to="/lead-generation-services/b2b-lead-generation-funnel-bottom-of-funnel"><h5 className="navbarh5as">Bottom-of-Funnel Leads</h5></Link>
                   <Link to="/lead-generation-services/b2b-lead-generation-funnel-bottom-of-funnel"><h5 className="navbarh5as">I Need More MQLs</h5></Link>
                 </div></div>
                </div>
                </div>
             </li>
              <li className="navbarlias"><Link to="/content-creation-solutions"style={{ color: this.state.status === "top" ? "white" : "#888888" }}>Content Creation</Link>
              <div className="sub_menu" style={{
                  backgroundColor: this.state.status === "top" ? "white" : "white",
                  color: this.state.status === "top" ? "white" : "#888888",
                  position: "fixed"}} >
                    <h1 className="navbarh1as" style={{color:"#32c24f" ,textAlign:"left"}}>B2B Content Creation</h1>
                  <div className="submenugridpart2as">
                 <div className="part1gridas">
                 <Link to="/"><img src={logop} alt="alt" className="logofor" /></Link>
                 </div>
                 <div className="part1gridas"> 
                 <Link to="/content-creation-solutions"> <h2 className="navbarh2as">Strategic Content</h2></Link>
                  <div className="margintopnavas">
                  <Link to="/content-creation-solutions/content-creations-and-marketing"> <h5 className="navbarh5as">Full-Service Content Production</h5></Link>
                   
                   </div>
                   </div>
                 <div className="part1gridas">
                 <Link to="/content-creation-solutions/content-survey">   <h2 className="navbarh2as">Content Sponsorship</h2></Link>
                 <div className="margintopnavas">
                 <Link to="/content-creation-solutions/content-survey">   <h5 className="navbarh5as">Current Sponsorship Topics</h5></Link>
                
                 </div>
                 </div>
                 <div className="part1gridas">
                 
                 <div className="margintopnavas">
                 <Link to="/content-creation-solutions/content-creations-and-marketing"> <h2 id="h2bcdf" className="navbarh2as">Information Discovery</h2></Link>
                 <div className="margintopnavas">

                 <Link to="/content-creation-solutions/content-survey">  <h5 className="navbarh5as">Survey Based Market Research</h5></Link>
                 </div>
                   
                 </div></div>
                </div>
                </div>
              </li>
              <li className="navbarlias"><Link to="/lead-total-development-solutions" style={{ color: this.state.status === "top" ? "white" : "#888888" }}>Lead Development</Link>
              <div className="sub_menu" style={{
                  backgroundColor: this.state.status === "top" ? "white" : "white",
                  color: this.state.status === "top" ? "white" : "#888888",
                  position: "fixed"}} >
                   <Link to="/lead-total-development-solutions"> <h1 className="navbarh1as" style={{color:"#ffa800" ,textAlign:"left"}}>B2B Lead Development</h1></Link>
                  <div className="submenugridas">
                 <div className="part1gridas">
                 <Link to="/"> <img src={logop} alt="alt" className="logofor" /></Link>
                 </div>
                 
                 <div className="part1gridas">
                 <Link to="/lead-generation-services/venalicium-b2b-lead-generation-funnel"><h2 className="navbarh2as">Full-Funnel</h2></Link>
                 <div className="margintopnavas">
                 <Link to="/lead-generation-services/venalicium-b2b-lead-generation-funnel"><h5 className="navbarh5as">I Need An SDR Team</h5></Link>
                 <Link to="/lead-total-development-solutions/venalicium-B2B-lead-conversion"><h5 className="navbarh5as">We Have Too Many Leads to Follow-Up</h5></Link>
                 <Link to="/lead-generation-services/venalicium-b2b-lead-generation-funnel"><h5 className="navbarh5as">I Need Appointment Setting</h5></Link>
                 <Link to="/lead-total-development-solutions/venalicium-B2B-lead-conversion"><h5 className="navbarh5as">I Need to Generate Pipeline Fast</h5></Link>
                 </div></div>
                 <div className="part1grid2as">
                 <Link to="/lead-total-development-solutions/venalicium-B2B-lead-conversion"> <h2 className="navbarh2as">Lead Follow-Up</h2></Link>
                 <div className="margintopnavas">
                 <Link to="/lead-total-development-solutions/venalicium-B2B-lead-conversion"><h5 className="navbarh5as">Cold Lead Warm-Up</h5></Link>
                 <Link to="/lead-total-development-solutions/venalicium-B2B-lead-conversion"><h5 className="navbarh5as">Content Lead Follow-Up</h5></Link>
                 <Link to="/lead-total-development-solutions/venalicium-B2B-lead-conversion"><h5 className="navbarh5as">Customer Marketing</h5></Link>
                 <Link to="/lead-total-development-solutions/venalicium-B2B-lead-conversion"><h5 className="navbarh5as">Pre &amp; Post Event Engagement</h5></Link>
                 </div></div>
                 <div className="part1gridas"> 
                  
                   </div>
                </div>
                </div>
              </li>
              <li className="navbarlias"><Link to="/technology-buyers"style={{  color: this.state.status === "top" ? "white" : "#888888" }}>Audience</Link>
              <div className="sub_menu" style={{
                  backgroundColor: this.state.status === "top" ? "white" : "white",
                  color: this.state.status === "top" ? "white" : "#888888",
                  position: "fixed"}} >

                  <div className="submenugridas2244">
                 <div className="part1gridas">
                 <img src={logop} alt="alt" className="logofor logoof" />
                 </div>
                
                <div>
                <Link to="/technology-buyers"><div className="h2dddss">
                 In-Market B2B Technology Audience Segments
                  </div></Link>
                 <div className="redcardnavbar">
                 <Link to="/technology-buyers/it-audience"> <div>
                    <Card title="IT"
                    image={ItImage}/>
                   </div></Link>
                   <Link to="/technology-buyers/sales-audience"><div>
                    <Card  title="Sales"
                    image={SalesImage}/>
                   </div></Link>
                   <Link to="/technology-buyers/hr-audience"><div>
                    <Card  title="HR"
                    image={HrImage}/>
                   </div></Link>
                   <Link to="/technology-buyers/business-audience"><div>
                   <Card title="Business"
                    image={BusinessImage}/>
                   </div></Link>
                   <Link to="/technology-buyers/finance-audience"><div>
                   <Card  title="Finance"
                    image={FinanceImage}/>
                   </div></Link>
                   <Link to="/technology-buyers/marketing-audience"><div>
                    <Card  title="Marketing"
                    image={MarketingImage}/>
                   </div></Link>
                 </div>
               
                </div>
                </div>
                </div>
              </li>
              <li ><Link className="navbarlias" to="/venalicium-b2b-blog-news"style={{  color: this.state.status === "top" ? "white" : "#888888" }}>Resource</Link>
              <div className="sub_menu" style={{
              
                  backgroundColor: this.state.status === "top" ? "white" : "white",
                  color: this.state.status === "top" ? "white" : "#888888",
                  position: "fixed"}} >
                    <h1 className="navbarh1as" style={{color:"#1f85de" ,textAlign:"left"}}>Demand Generation Research</h1>
                  <div className="submenugridresourceas">
                 
                 <div className="part1gridas"> 
                 <Link to="/venalicium-b2b-blog-news"> <h2 className="navbarh2as">“Lead Generation While We Sleep”: Account Based Marketing Leads from MX’s Target Accounts</h2></Link>
                  <div className="margintopnavas">
                   {/* <h5 className="navbarh5as">CircleCI Uncovers Leads Within A Niche DevOps World</h5> */}
    
                   </div>
                   </div>
                 <div className="part1gridas">
                 <a href="https://www.v3webinar.com"> <h2 className="navbarh2as">Venalicium B2B Blog</h2></a>
                 <div className="margintopnavas">
                 <a href="https://www.v3webinar.com"> <h5 className="navbarh5as">A Virtual Rendezvous For Event Professionals </h5></a>
                    {/*  
                   B2B Customer Experience Excellence</h5>
                   <h5 className="navbarh5as">Every B2B SEO Strategy Needs…</h5> */}
                 </div></div>
                 <div className="part1grid2as">
                 <Link to="/venalicium-b2b-blog-news">  <h2 className="navbarh2as">Venalicium B2B News</h2></Link>
                 <div className="margintopnavas">
              
                 <Link to="/venalicium-b2b-blog-news">     <h5 className="navbarh5as">Keep Yourself Updated</h5></Link>
                      {/*  
                   PureB2B Announces Strategic Evolution to a Full-Funnel Demand Generation Partner</h5>
                   <h5 className="navbarh5as">2019 Inspire to Act</h5> */}

                 </div></div>
                </div>
                </div>
              </li>
              <li className="navbarlias"><Link to="/aboutus-lead-generation-services"style={{ color: this.state.status === "top" ? "white" : "#888888" }}>About Venalicium B2B</Link>
             
              </li>
            </ul>
            <Button linka="/contact-venalicium-b2b" title="Contact Us"  style={{marginTop:"40px" ,marginRight:"20px",textDecoration:"none"}} />
          </div>

          {/* ============================================================================================================================================================= */}
          <div className="navNarrow" style={{backgroundColor: this.state.status === "top" ? "transparent" : "white"}}>
            <div className="differentgrid">
             <Link to="/"> <img src={logop}  className="imagechotanav" alt="alt"/></Link>
					<i className="fa fa-bars fa-2x iconfloats" style={{float:"right", marginTop:"30px",marginRight:"5%", color: this.state.status === "top" ? "white" : "#000000" }} onClick={this.burgerToggle}></i>
          </div>
					<div className="narrowLinks" >
					<div><Link className="borderbb" to="/lead-generation-services" onClick={this.burgerToggle}>Lead Generation</Link>
           <div className="faicondiv"><i className="fa fa-plus " name="alphaBeta" onClick={this.burgerToggleadd}></i></div><div className="narrowLinksadd">
          <Link className="borderbba" to="/lead-generation-services" >Top Of Funnel</Link>
					<Link className="borderbba" to="/lead-generation-services/abm-lead-generation" >Account Based Marketing</Link>
				
          <Link className="borderbba" to="/lead-generation-services/content-syndication-lead-generation">Content Syndication</Link>
         
          <Link className="borderbba" to="/lead-generation-services/b2b-lead-generation-trends-inmarket-leads">In Market Leads</Link>
				
          </div>
          </div>
          {/* =========================================================================================== */}
					<div><Link className="borderbb" to="/content-creation-solutions" onClick={this.burgerToggle}>Content Creation</Link>
           <div className="faicondiv"><i className="fa fa-plus" name="alphaBeta" onClick={this.burgerToggleadd1}></i></div><div className="narrowLinksadd1">
          <Link className="borderbba" to="/content-creation-solutions/content-creations-and-marketing" >Full Service</Link>
					<Link className="borderbba" to="/content-creation-solutions/content-survey" >Information Discovery</Link>
				
          </div>
          </div>
          {/* =========================================================================================== */}
					<div><Link className="borderbb" to="/lead-total-development-solutions" onClick={this.burgerToggle}>Lead Development</Link>
           <div className="faicondiv"><i className="fa fa-plus" name="alphaBeta" onClick={this.burgerToggleadd2}></i></div><div className="narrowLinksadd2">
          <Link className="borderbba" to="/lead-total-development-solutions/venalicium-B2B-lead-conversion">Lead Follow Up</Link>
					<Link className="borderbba" to="/lead-generation-services/venalicium-b2b-lead-generation-funnel">Full Funnel</Link>
				
          </div>
          </div>
          {/* =========================================================================================== */}
					<div><Link className="borderbb" to="/technology-buyers" onClick={this.burgerToggle}>Audiences</Link>
           <div className="faicondiv"><i className="fa fa-plus " onClick={this.burgerToggleadd3}></i></div><div className="narrowLinksadd3">
          <Link className="borderbba" to="/technology-buyers/it-audience" >Information Technology</Link>
					<Link className="borderbba" to="/technology-buyers/sales-audience" >Sales</Link>
					<Link className="borderbba" to="/technology-buyers/hr-audience" >Human Resources</Link>
					<Link className="borderbba" to="/technology-buyers/finance-audience" >Finance</Link>
					<Link className="borderbba" to="/technology-buyers/buisness-audience" >Business</Link>
					<Link className="borderbba" to="/technology-buyers/marketing-audience" >Marketing</Link>
          </div>
          </div>
          {/* =========================================================================================== */}
					<div><Link className="borderbb" to="/venalicium-b2b-blog-news" onClick={this.burgerToggle}>Resources</Link>
           <div className="faicondiv"><i className="fa fa-plus " onClick={this.burgerToggleadd4}></i></div><div className="narrowLinksadd4">
          <Link className="borderbba" to="/venalicium-b2b-blog-news" >Venalicium B2B News</Link>
					<a href="https://www.v3webinar.com" className="borderbba">Venalicium Blogs</a>
				
          </div>
          </div>
          {/* =========================================================================================== */}
					<div><Link className="borderbb" to="/aboutus-lead-generation-services" onClick={this.burgerToggle}>About Venalicium B2B</Link>
      
         
          </div>
          {/* =========================================================================================== */}
				
					</div>
				</div>
				</div>
        </div>
     
    )
  }
}
