import React, { useEffect } from 'react'
import '../styles/ResourceCard.css'
export default function ResourceCard({ image, title, subtitle, link }) {
    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])
    return (
        <div>
            <div className="ResourceCardMainContainerKV" data-aos="zoom-in">
                <img className="imageContainerOfResourceCardKV" src={image} alt="alt" />
                <div className="titleresourcecardKV"> <h1 className="titleContainerOfResourceKV">{title}</h1></div>
            </div>
        </div>
    )
}
