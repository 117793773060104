import React, { useEffect } from 'react'
import '../styles/InMeeting.css'
import Button from '../component/Button'
import Card from '../component/Card'
import CardSmall from '../component/CardSmall'

import TakeOwnershipImage from '../images/Take Ownership@4x.png'
import CombinedLeadGenerationImage from '../images/Combine Lead Generation@2x.png'
import EmailImage from '../images/Email.png'
import PhoneImage from '../images/Phone.png'
import ComputerImage from '../images/Display-Ads.png'
import ImageDiv3 from '../images/landingcoverVector.png'


import ItImage from '../images/IT@2x.png'
import SalesImage from '../images/Sales icon@2x.png'
import HrImage from '../images/HR@10x.png'
import MarketingImage from '../images/Marketing@2x.png'
import FinanceImage from '../images/Finance@10x.png'
import BusinessImage from '../images/buisiness@10x.png'
import Footer from '../component/Footer'
import Talk from '../component/Talk'
import Navbar from '../component/Navbar'
export default function InMeeting() {
    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])
    return (
        <div>
            <Navbar />
            <div className="coverDivInMeetingKV">
                <h1 className="h1coverinMeetingDivKV">Venalicium B2B :</h1>
                <h1 className="h1InMeetingDivKVCover">Integrated Turnkey <br />Market Development</h1>
            </div>

            <div className="Div1InMeetingKV">
                <div>
                    <h1 className="h1Div1InmeetingTakeOwnerKV">Take Ownership of the Sales & Marketing Funnel</h1>
                    <p className="paraDiv1TakeownershipInmeetingKV">
                        In-market customers are found based on predictive analysis using outbound sales development.
                        We help you by doing the work of looking for who is actually in the market and would talk to
                        you for your service. This would deliver BoFu leads that are already involved in the buyer’s
                        journey and would be cost-effective too, whether it is money, time or resources!
                        </p>
                    <div className="buttonDiv1TakenownershipKV"><Button linka="/contact-venalicium-b2b" title="CONTACT US" /> </div>
                </div>
                <div>
                    <img src={TakeOwnershipImage} alt="alt" className="TakeOwnershipImageInmeetingKV" />
                </div>
            </div>

            <div className="Div2InmeetingKV">
                <div>
                    <h1 className="h1div2InMeetingHOWWORKkv">How Venalicium Funnel Works</h1>
                    <p className="paraDiv2HowWorkKV">
                        Identify in-market companies based on predictive analytics
                        Our online and offline intent generation engines combine to provide intent data that is solely
                        exclusive to V3B2B and our clients. By aggregating both online and phone-verified intent, V3B2B identifies
                        companies that are in-market and integrates your chosen keywords to build a targeted account list.
                        With digital intent partnerships with over 600,000 sites, we’re able to identify and analyze content
                        consumption for peaks in activity across accounts. Offline intent is equally as important as online intent,
                        and we verify buyer intent via phone survey.
                        Our team of industry experts contacts tech buyers via phone to find those looking to make a purchase.
</p>
                </div>
                <div>
                    <div><img src={CombinedLeadGenerationImage} alt="alt" className="HowWorkImageDiv2KV" /> </div>
                </div>
            </div>
            <div className="headingDiv3InMeetingKV">U.S. agents deploy integrated lead development sequence targeting in-market companies.

</div>

            <div className="Div4InMeetingKV">
                <div>
                    <Card image={PhoneImage}

                        subtitle="1. U.S.-based phone team executes outbound phone reach with a timeframe developed around the client’s needs." />
                </div>
                <div>
                    <Card image={EmailImage}

                        subtitle="2. Email campaigns drive engagement to client’s websites promoting their digital offers: Free trial/demo promotion, webinar/online event promotion, white paper content/eBooks" />
                </div>
                <div>
                    <Card image={ComputerImage}

                        subtitle="3. Display banners target the buying units at desired in-market companies and direct to the client’s site promoting strategic branding, content, or offers." />
                </div>
            </div>


            <div className="Div3rdInMeetingBofuKV">
                <div>
                    <h1 className="h1Div3rdInMeetingBofuKV">Deliver BoFu leads that have in-market engagement</h1>
                    <p className="paraDiv3rdMeetingBofuKV">Our online and offline intent generation engines combine
                    to provide intent data that is solely exclusive to V3B2B and our clients. By aggregating both online
                    and phone-verified intent, V3B2B identifies companies that are in-market and integrates your chosen
                    keywords to build a targeted account list. With digital intent partnerships with over 600,000 sites,
                    we’re able to identify and analyze content consumption for peaks in activity across accounts.
                    Offline intent is equally as important as online intent, and we verify buyer intent via phone survey.
                    Our team of industry experts contacts tech buyers via phone to find those looking to make a purchase.

                    Clients receive leads with strong positive dispositions. Leads provided are ready to further their buyer journey:

                    Requested information
                    Expressed future interest
                    Ready to schedule a meeting
Online demo request</p>
                </div>
                <div>
                    <div><img src={ImageDiv3} alt="alt" className="ImageDiv3InMeetingInMarketKV" /> </div>
                </div>
                {/* Take ownership of the sales and marketing
                    pipeline and increase lead generation ROI with a cost-effective, turnkey solution. */}
            </div>


            <div className="headingDiv4InMeetingKV">THE V3B2B GLOBAL AUDIENCE OF MORE THAN 125 MILLION TECHNOLOGY BUYERS</div>
            <div className="smallCrdInMarketKV">
                <CardSmall
                    linka="/technology-buyers/it-audience"
                    title="IT"
                    image={ItImage} />

                <CardSmall
                    linka="/technology-buyers/it-audience"
                    title="Sales"
                    image={SalesImage} />

                <CardSmall
                    linka="/technology-buyers/it-audience"
                    title="HR"
                    image={HrImage} />

                <CardSmall
                    linka="/technology-buyers/it-audience"
                    title="Business"
                    image={BusinessImage} />

                <CardSmall
                    linka="/technology-buyers/it-audience"
                    title="Finance"
                    image={FinanceImage} />

                <CardSmall
                    linka="/technology-buyers/it-audience"
                    title="Marketing"
                    image={MarketingImage} />
            </div>
            <div className="talkdiv">
                <Talk heading1="Take ownership of the sales and marketing " heading2="pipeline and increase lead generation ROI with a cost-effective, turnkey solution." />
            </div>


            <Footer />
        </div>
    )
}
