import React, { useEffect } from 'react'
import '../styles/Redcard.css'
import { Link } from 'react-router-dom'

export default function Redcard({ linka, title, subtitle2, subtitle6, subtitle4, subtitle5, subtitle3, subtitle, subtitle1, image, style }) {
    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])
    return (
        <div className="redcardbody" >
            <Link to={linka} style={{ textDecoration: "none" }} >
                <div className="redborder">
                    <div className="titleclass" >{title}</div>
                </div>
                <div className="middleclass">
                    {subtitle}
                </div>
                <div className="middleclass22">
                    {subtitle3}
                </div>

                <div className="middleclass22">
                    {subtitle4}
                </div>
                <div className="middleclass22">
                    {subtitle5}
                </div>
                <div className="middleclass22">
                    {subtitle6}
                </div>
                <div className="middleclass2">
                    {subtitle2}
                </div>



                <div className="middleclass1" style={style}>
                    {subtitle1}
                </div>
                <div className="lastdiv1"></div>
            </Link>
        </div>

    )
}
