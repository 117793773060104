import React from 'react'
import '../styles/CardSmall.css'
import { Link } from 'react-router-dom'

export default function Card({ image, title, subtitle, linka, subtitle1, subtitle2 }) {

    return (
        <div className="maindivsmallcardsKV" data-aos="flip-left">
            <Link to={linka}>
                <div className="cardvenaliciumKV">
                    <img className="cardimgKV" src={image} alt="alt" />
                    <h1 className="cardh1jsKV">{title}</h1>
                    <h3 className="cardh3jsKV">{subtitle}</h3>
                    <h3 className="cardh3jsKV">{subtitle1}</h3>
                    <h3 className="cardh3jsKV">{subtitle2}</h3>
                </div>
            </Link>
        </div>
    )
}