import React, { useEffect } from 'react'
import '../styles/BottomOfFunnel.css'

import tick from '../images/tick@4x.png'
import one from '../images/1@4x.png'
import two from '../images/2@4x.png'

import three from '../images/3@4x.png'
import Numbercard from '../component/Numbercard'
import map from "../images/worldmap.png"
import Footer from '../component/Footer'
import Talk from '../component/Talk'
import Navbar from '../component/Navbar'


export default function BottomOfFunnel() {
    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])
    return (
        <div>

            <Navbar />

            <div className="bofas">
                <h2 className="h2bofas" data-aos="fade-up-right">VENALICIUM B2B FUNNEL:</h2>
                <h1 className="h1bofas" data-aos="fade-up-left" >Integrated Turn-Key <br />Market Development</h1>
            </div>
            <div className="containerbofas" data-aos="zoom-in">
                <h1 className="h1bofpart1as" >Take Ownership of the Sales & Marketing Funnel</h1>
                <p className="parabofas">The two main challenges that demand generation marketers face:
                <br />1.Generating a consistent stream of high-quality leads
                        <br /> 2.Improper follow-up when those leads are transitioned to sales
                        <br />Boost up your demand generation ROI by being the boss of your sales and marketing pipeline!
                        </p>

            </div>
            <div className="part2bofas">
                <div className="containerbofas">
                    <div className="gridpart3bofas">
                        <div data-aos="zoom-in-right">
                            <h1 className="h1abmpart1as">Optimize the Full Funnel to Increase Conversions</h1>
                            <p className="parapart2bofas">
                                Venalicium B2B Funnel solution provides down-funnel outbound lead generation to
                                help clients address the roadblocks they’ve hit generating bottom-of-funnel leads.
                                Our B2BFunnel solves for challenges such as:
                             </p>
                            {/* <Button title="Contact Us" style={{ marginLeft: "50px" }} /> */}
                            <div className="tickboxas">
                                <img className="tick" src={tick} alt="alt" /><h6 className="h5tickas">
                                    Lack of internal infrastructure, i.e. no SDRs,
                                    <br />
                                    to produce or further develop BoFu leads.
                                    </h6>
                            </div>
                            <div className="tickboxas">
                                <img className="tick" src={tick} alt="alt" /><h6 className="h5tickas">
                                    No marketing nurture</h6>
                            </div>
                            <br />
                            <div className="tickboxas">
                                <img className="tick" src={tick} alt="alt" /><h6 className="h5tickas">
                                    The need to grow opportunities for their sales team quickly
                                    </h6>
                            </div>
                            <div className="tickboxas">
                                <img className="tick" src={tick} alt="alt" /><h6 className="h5tickas">
                                    Too many MQLs and not enough people to follow up on them.
                                    </h6>
                            </div>
                        </div>
                        <div></div>
                    </div>
                    <h7 className="h7bofas">

                    </h7>
                    <h1 className="h1bofdjas">How Venalicium Funnel Works</h1>
                    <div className="Numbergridas">
                        <div className="rowfordiv">
                            <Numbercard image={one} />


                            <div className="h1h7as" data-aos="zoom-in">
                                <h1 className="h1forpart1as">
                                    Identify In-market Companies with Predictive Analytics
                        </h1>
                                <p>
                                    Our online and offline intent generation engines combine and provide intent
                                    data that is exclusive to us and our clients. We are able to recognize and analyze
                                    content consumption with our digital partnerships with over 6 million sites.
                                    We contact tech buyers those who are
                                    looking to make a purchase through our team of industry experts via phone.
                                       </p>
                            </div>
                        </div>
                        <div className="rowfordiv">
                            <Numbercard className="numbercardas" image={two} />
                            <div className="h1h7as" data-aos="zoom-in">
                                <h1 className="h1forpart1as">
                                    U.S. Agents Deploy Lead Development Sequence
                        </h1>
                                <p>
                                    We reach out to engage the targets through multi-touch integrated marketing approach.
                                    U.S based team executes outbound contact calls. Email campaigns drive involvements to
                                    client digital collateral and online offers. Targeting the buying units at desired in-market
                                    companies is done by display banners.
                                    Also promotion of strategic branding, content, offers direct through the client’s site.
                                     </p>
                            </div>
                        </div>       <div className="rowfordiv">
                            <Numbercard className="numbercardas" image={three} />
                            <div className="h1h7as" data-aos="zoom-in">
                                <h1 className="h1forpart1as">
                                    The Delivery of In-Market Bottom of Funnel Leads
                        </h1>
                                <p>
                                    During the execution of our multi-touch outreach campaign,
                                    we pass the prospects that show a strong indication of being in-market to you and your sales team.
                                    This would include leads that have requested information, expressed an immediate or
                                    future interest or those who have requested for a meeting or demo.
                                    Hence, you will only receive leads that show actual positive interest.
                                        </p>
                            </div>

                        </div>
                    </div>

                </div>
                <div className="lastcontaineras">
                    <div className="containerbofas">
                        <Talk heading1="Take ownership of the sales and marketing pipeline and increase lead generation ROI with a cost-effective, turn-key solution." />
                    </div>
                </div>
                <div className="containerbofas"><div className="coloras">
                    <h1 className="h1bofpart1as" data-aos="fade-in">THE V3B2B GLOBAL AUDIENCE OF MORE THAN 125<br /> MILLION TECHNOLOGY BUYERS</h1>
                </div>
                    <div className="worldmap">
                        <img src={map} alt="alt" className="worldmap" data-aos="zoom-in" />
                    </div>
                </div>
                <Footer />
            </div>


        </div >
    )
}
