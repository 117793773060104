import React from 'react'
import '../styles/Numbercard.css'
export default function Numbercard({ image }) {
    return (
        <div className="cardvault">
            <div className="cardvenaliciumdjas" data-aos="flip-left">
                <img className="cardimgdjas" src={image} alt="alt" />
            </div>
        </div>
    )
}
