import React, { useEffect } from 'react'
import '../styles/Contactus.css'
import Footer from '../component/Footer'

// import back from '../images/contactback.png'
import Clock from '../component/Clock'
import Navbar from '../component/Navbar2'
export default function Contactus() {
    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])
    return (
        <div >
            <Navbar />

            <div className="firstback">

                <div className="backgroundimagecontact">
                    <div style={{ textAlign: "center" }}>
                        <h1 className="h1asdj">Generate Demand Like Nobody's Business</h1>
                        <h1 className="h2asdj">Contact Us</h1>
                    </div>
                    <div className="contactdivmargin">
                        <form action="https://formsubmit.co/info@v3media.online" method="POST">
                            <input type="hidden" name="_next" value="https://www.venaliciumb2b.com/" />
                            <div className="gridname">
                                <div className="buteeed ">
                                    <i className="manti  fa fa-user" style={{ color: "#000" }} />
                                    <input required type="text" className="aaac" name="first_name" id="name" placeholder="First Name" />
                                </div>
                                <div className="buteeed aass">
                                    <i className="manti  fa fa-user" style={{ color: "#000" }} />
                                    <input required type="text" className="aaac" name="Last_name" id="name" placeholder=" Last Name" />
                                </div>
                            </div>
                            <div className="gridname">
                                <div className="buteeed ">
                                    <i className="manti  fa fa-envelope" style={{ color: "#000" }} />
                                    <input required type="email" id="mail" className="aaac" name="email" placeholder="Email Address" />
                                </div>
                                <div className="buteeed aass">
                                    <i className="manti  fa fa-briefcase" style={{ color: "#000" }} />
                                    <input required type="text" className="aaac" name="company-name" id="msg" placeholder="Company Name" />
                                </div>
                            </div>
                            <div className="buteeed  ">
                                <i className="manti  fa fa-pen" style={{ color: "#000" }} />
                                <input required type="text" className="aaac" name="company-name" id="msg" placeholder="Title" />
                            </div>
                            <div className="buteeed ">
                                <i className="manti  fa fa-pen" style={{ color: "#000" }} />
                                <textarea required type="text" className="aaac" name="company-name" id="msg" placeholder="Message" ></textarea>
                            </div>

                            <div class="butcheck">
                                <input required id="kuchbhi" type="checkbox" name="_captcha" value="true" />
                                <label className="label" for="kuchbhi">
                                    I authorise Venalicium B2B and partners to contact through email and phone </label>
                            </div>
                            <div style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                                <div className="buteded faru">
                                    <input required id="captcha" type="checkbox" name="_captcha" value="true" />
                                    <p style={{ color: "white", marginTop: "10%" }}>I'm not a robot</p>
                                    <img src="https://upload.wikimedia.org/wikipedia/commons/thumb/a/ad/RecaptchaLogo.svg/1200px-RecaptchaLogo.svg.png"
                                        alt="alt" className="asdfgh" />
                                </div>
                            </div>
                            <div class="butuonasdj">
                                <button type="submit" className="buttonas ">Submit</button>
                                <input type="hidden" name="_captcha" value="false" />
                            </div>
                        </form>
                    </div>
                </div>



            </div>
            <div className="clockcssjs">


                <div className="chica">
                    <div className="rowgrid">
                        <Clock title="Chicago" datediff={13} />
                        <p style={{ color: "#ffa800" }}>4075, Meadow View Drive, <br />St. Charles , IL 60175 <br />Phone Number: 630-326-8036<br />Mail:info@venaliciumdigital.com</p>
                    </div>
                </div>


                <div className="calfor">
                    <div className="rowgrid">
                        <Clock title="California" datediff={11} />
                        <p style={{ color: "#1f85de" }}> 2468 S Bascom Ave,  <br />#4,Campbell, CA 95008 <br />Phone Number: 630-326-8036<br />Mail:info@venaliciumdigital.com  </p>
                    </div>
                </div>
                <div className="banga">
                    <div className="rowgrid">
                        <Clock title="Bangalore" datediff={0} />
                        <p style={{ color: "#32c24f" }}>RMV-II stage, Dollars Colony, <br />Opp 2nd Cross,Bangalore 560094<br />Phone Number: +91-8043023516<br />Mail:info@venaliciumdigital.com</p>

                    </div>
                </div>
            </div>
            <Footer />
        </div >


    )
}
