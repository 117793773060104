import React, { useEffect } from 'react'
import '../styles/StrategicContent.css'
import card from "../images/lapsc.png"
import tablet from '../images/div1sc.png'
import marketing from '../images/contentsc.png'
import Button from '../component/Button'
import Talk from '../component/Talk'
import map from "../images/worldmap.png"
import ItImage from '../images/IT@2x.png'
import SalesImage from '../images/Sales icon@2x.png'
import HrImage from '../images/HR@10x.png'
import MarketingImage from '../images/Marketing@2x.png'
import FinanceImage from '../images/Finance@10x.png'
import BusinessImage from '../images/buisiness@10x.png'
import CardSmall from '../component/CardSmall'

import Footer from '../component/Footer'
import Navbar from '../component/Navbar'

export default function StrategicContent() {
    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])
    return (
        <div>
            <Navbar />
            <div className="wallpaper5as">
                <div className="headingwallpaper1">
                    <h1 className="h2wallpaper1">CONTENT THAT CONVERTS</h1>
                    <h1 className="h1wallpaper1">Content Creation Solutions</h1>
                    <div >
                        <img src={card} alt="alt" className="imgsrcaadd" />


                    </div>
                </div>
            </div>
            <div className="leftgrid1">
                <div className="leftside1">
                    <h2 className="lefthead">V3Syndication:<br />Content Syndication With Intent Data</h2>
                    <p className="leftpara">Venalicium B2B Content Syndication targets and engages the right B2B Buyers, which pushes your content into the hands of you target by using multi-channel outreach efforts.</p>
                    <div className="marginforbutton">
                        <Button linka="/lead-generation-services/content-syndication-lead-generation" title="Learn About B2BSyndication" style={{ width: "50%" }} />
                    </div>
                </div>

                <div className="imggrid1">
                    <img src={tablet} alt="alt" className="imggrid1" />
                </div>
            </div>
            <div className="gridleftimage ">
                <div className="imagecontainer">
                    <img className="imageingrid" src={marketing} alt="alt" />
                </div>
                <div className="paragraphdiv " >
                    <div>
                        <h1 className="h1part1as">VenaliciumABM: <br />Account-Based Programmatic Display Management</h1>
                        <p className="paradetails">VenaliciumABM is a unique display advertising solution that offers an intent-driven, integrated approach to existing demand and lead generation programs, enabling you to reach the entire buying committee within target accounts.</p>
                        <div className="marginforbutton">
                            <Button linka="/lead-generation-services/abm-lead-generation" title="Learn More About B2BABM " style={{ width: "50%" }} />
                        </div>
                    </div>
                </div>
            </div>
            <div className="talkdiv">
                <Talk heading1="Accelerate Your Content Strategy " heading2="Today! " />
            </div>
            <div className="h1textaligncenter1" style={{ textTransform: "uppercase" }}>THE VENALICIUM B2B GLOBAL AUDIENCE OF MORE THAN 125 MILLION TECHNOLOGY BUYERS</div>
            <div className="smallCrdInMarketKV">
                <CardSmall
                    linka="/technology-buyers/it-audience"
                    title="IT"
                    image={ItImage} />

                <CardSmall
                    linka="/technology-buyers/sales-audience"
                    title="Sales"
                    image={SalesImage} />

                <CardSmall
                    linka="/technology-buyers/hr-audience"
                    title="HR"
                    image={HrImage} />

                <CardSmall
                    linka="/technology-buyers/business-audience"
                    title="Business"
                    image={BusinessImage} />

                <CardSmall
                    title="Finance"
                    linka="/technology-buyers/finance-audience"
                    image={FinanceImage} />

                <CardSmall
                    linka="/technology-buyers/marketing-audience"
                    title="Marketing"
                    image={MarketingImage} />

            </div>
            <div className="worldmap1" >
                <img src={map} alt="alt" className="worldmap" data-aos="zoom-in" />
            </div>
            <Footer />
        </div>
    )
}
