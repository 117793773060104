import React, { useEffect } from 'react'
import '../styles/Information.css'
import AboutImg from '../images/about.png'
import card1 from '../images/card1.png'
import card2 from '../images/card2.png'
import card3 from '../images/card3.png'
import BenefitsImg from '../images/benefits.png'
import Card from '../component/Card'
import Talk from '../component/Talk'
import Footer from '../component/Footer'

import { Link } from 'react-router-dom'
import Navbar from '../component/Navbar'
export default function Information() {
    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])
    return (
        <div>
            <Navbar />
            <div className="wallpaper4as">
                <div className="headingwallpaper">
                    <h1 className="h2wallpaper">VENALICIUM SURVEY:</h1>
                    <h1 className="h1wallpaper">B2B Technology <br /> Buyer Insights</h1>
                </div>
            </div>
            <div className="marginleftvsright">
                <div className="V3-about-kp">
                    <div className="V3-about-img-div-kp">
                        <img className="V3-about-img-kp" src={AboutImg} alt="about" />
                    </div>

                    <div className="V3-about-text-div-kp">
                        <h3 className="V3-about-h2-kp">What are Your Target B2B Buyers <br />Looking For?</h3>
                        <p className="V3-about-para1-kp">
                            The needs and challenges technology buyers face are very hazy and perplexed.
                            Analyzing the shifting priorities in today’s B2B landscape
                            and implementing those is key to build a content strategy that will resonate with your target personas.
                </p>
                        <p className="V3-about-para2-kp">
                            When you work with a demand generation partner that actively surveys your market
                            can save your bottom line and accelerate your business intelligence process.
                            Implementing these and discovering valuable market insights requires a vast amount of internal resources.
                </p>
                        <Link to="/contact-venalicium-b2b"><button className="V3-about-button-kp">contact us today</button></Link>
                    </div>
                </div>
            </div>
            <div className="V3-benefits-kp">
                <div className="V3-benefits-img-div-kp">
                    <img src={BenefitsImg} className="V3-benefits-img-kp" alt="about" />
                </div>
                <div className="V3-benefits-text-div-kp">
                    <h3 className="V3-benefits-h2-kp">Improve Your Strategic Content <br /> with Insights from V3Survey</h3>
                    <p className="V3-benefits-para1-kp">
                        We utilize the B2B intent-based survey engine to uncover valuable market insights to help you
                        drive strategic content. This is developed to surface your target buyer’s changing priorities
                    </p>
                    <p className="V3-benefits-para2-kp">
                        Customized survey programs connect directly with the decision
                        makers you need to reach, ensuring that the data is accurate and
                        up-to-date.
                </p>
                    <p className="V3-benefits-para3-kp">
                        Come, talk to one of our team members to discuss your buyer insight needs.
                        </p>
                    <Link to="/contact-venalicium-b2b"><button className="V3-about-button-kp">contact us today</button></Link>
                </div>
            </div>
            <h1 className="h1textaligncenter">Benefits of VenaliciumSurvey</h1>
            <div className="cardsgrid">
                <div className="cardsingle"><Card image={card1} title="Persona Filtering" subtitle=" The power to target by demographic
                        frmographic, and technographic
                        flters" /></div>
                <div className="cardsingle"><Card image={card2} title=" Our Brands
                        " subtitle="HR Trends, IT Management Trends,
                        Sales Technology Trends, IT Security
                        Trends, Business Software Trends, Marketing Technology Trends" /></div>
                <div className="cardsingle"><Card image={card3} title="Multi-Channel Engagement " subtitle="  Email and telephone outreacht
                        targeting your persona flters" /></div>
            </div >
            <div className="talkdiv">
                <Talk heading1="Ready to talk  " heading2="Content Creation?" />
            </div>

            <Footer />
        </div>
    )
}
