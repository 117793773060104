import React from 'react'
import '../styles/CardSmall.css'

export default function Card({ image, title, subtitle, subtitle1, subtitle2 }) {

    return (
        <div className="maindivsmallcardsKV1" data-aos="flip-left">
            <div className="cardvenaliciumKV1">
                <img className="cardimgKV1" src={image} alt="alt" />
                <h1 className="cardh1jsKV1">{title}</h1>
                <h3 className="cardh3jsKV">{subtitle}</h3>
                <h3 className="cardh3jsKV">{subtitle1}</h3>
                <h3 className="cardh3jsKV">{subtitle2}</h3>
            </div>
        </div>
    )
}